import { popUp, requests } from "../../../utils";
import Spinner from "../../../utils/Spinner";
const spinner = new Spinner();

export default function save(data) {
  if (!data.clientName) {
    return {
      error: "Por favor adicione um nome de cliente antes de prosseguir.",
    };
  }
  spinner.start("salvando...");
  let user = JSON.parse(localStorage.getItem("user"));
  let body = { ...data };
  body.user = user._id;
  body.modelSheet = body.sheet;
  delete body.product;
  delete body.productId;
  delete body.sheet;
  for (let i = 0; i < body.modelSheet.length; i++) {
    let item = body.modelSheet[i];
    let isValidItem = validateItem(item);
    if (!isValidItem.valid) {
      spinner.finish();
      let error = `Preencha o campo ${isValidItem.error}, na Linha  ${i + 1}`;
      return {
        error,
      };
    }
  }
  let req = {
    path: body.sheetId ? `/sheet/${body.sheetId}` : "/sheet",
    options: {
      method: body.sheetId ? "PUT" : "POST",
      body,
    },
  };
  return requests(req)
    .then((res) => {
      spinner.finish();
      if (res && res.error) {
        if (res.status < 405) {
          return { error: res.error };
        } else {
          return {
            error: "Erro na comunicação com o servidor",
          };
        }
      } else {
        return { valid: true };
      }
    })
    .catch((e) => {
      spinner.finish();
      console.error(e);
      return {
        error: "Erro ao salvar plano de corte, tente novamente.",
      };
    });
}

const validateItem = (item) => {
  let resp = { valid: false, error: "" };
  if (!item.quant) {
    return { ...resp, error: "QUANTIDADE" };
  }
  if (!item.esp) {
    return { ...resp, error: "ESPESSURA" };
  }
  if (!item.larg) {
    return { ...resp, error: "LARGURA" };
  }
  if (!item.comp) {
    return { ...resp, error: "COMPRIMENTO" };
  }
  if (!item.material) {
    return { ...resp, error: "MATERIAL" };
  }
  if (!item.chapa) {
    return { ...resp, error: "CHAPA" };
  }
  if (!item.funcao) {
    return { ...resp, error: "PEÇA" };
  }
  if (!item.fita) {
    return { ...resp, error: "FITA" };
  }
  return { ...resp, valid: true };
};
