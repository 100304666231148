import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

function Card(props) {
  return (
    <div
      onMouseDown={props.onclick}
      hidden={props.hidden}
      className="__Card_component"
    >
      <img
        className="__Card_image"
        hidden={!props.image}
        src={props.image}
        alt={props.imageAlt}
      />
      <div className="__Card_content_container">
        <h5 id="title">
          <strong>{props.title}</strong>
        </h5>
        {props.children}
      </div>
      <div className="__Card_buttons_container">{props.actions}</div>
    </div>
  );
}

Card.propTypes = {
  hidden: PropTypes.bool,
  title: PropTypes.string,
  onclick: PropTypes.func,
  actions: PropTypes.element,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
};

export default Card;
