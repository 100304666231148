import React from "react";
import { ToastProvider } from "react-toast-notifications";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import StoreProvider from "./components/Store/Provider";
import RoutesPrivate from "./components/Routes/Private";

import Home from "./pages/Home";
import Recovery from "./pages/Recovery";
import Application from "./pages/Application";

function App() {
    return (
        <ToastProvider autoDismiss autoDismissTimeout={6000} placement='top-right'>
            <Router id='App'>
                <StoreProvider>
                    <Switch>
                        <Route exact path='/' component={Home} />
                        <Route path='/recovery' component={Recovery} />
                        <RoutesPrivate path='/app' component={Application} />
                        <Route path='/*' component={Home} />
                    </Switch>
                </StoreProvider>
            </Router>
        </ToastProvider>
    );
}

export default App;
