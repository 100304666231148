import { requests, popUp } from "../../utils";

export function requestList() {
    return requests({
        path: "/sheet/list"
    })
        .then((res) => {
            if (res && res.error) {
                if (res.status < 405) {
                    return { error: res.error };
                } else {
                    return { error: "Erro na comunicação com o servidor" };
                }
            } else {
                return res;
            }
        })
        .catch((e) => {
            console.error(e);
            return { error: "Erro ao buscar lista de serviços." };
        });
}

export function removeSheet(sheetId) {
    return requests({
        path: `/sheet/${sheetId}`,
        options: {
            method: "DELETE"
        }
    })
        .then((res) => {
            if (res && res.error) {
                if (res.status < 405) {
                    return { error: res.error };
                } else {
                    return { error: "Erro na comunicação com o servidor" };
                }
            } else {
                return res;
            }
        })
        .catch((e) => {
            console.error(e);
            return { error: "Erro ao remover serviço." };
        });
}
