import styled from "styled-components";

export const Input = styled.input`
    border: transparent;
    border-bottom: solid thin var(--light);
    padding: 0.4rem;
    background: transparent;
    color: var(--light);
    flex: 1 0 250px;
    width: 100%;
    max-height: 45px;
    outline: none;
    font-size: 12pt;
    margin-bottom: 1rem;

    &:focus {
        background-color: rgba(255, 255, 255, 0.08);
        border-bottom-color: var(--primary);
    }
`;
