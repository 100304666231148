import React, { useState } from "react";

import PropTypes from "prop-types";
import { useEffect } from "react";

import { PopMenuWrapper } from "./styles";

export function PopMenu(props) {
    let { caption, hidden } = props;
    const [state, setState] = useState({
        hidden
    });

    useEffect(() => {
        setState({
            ...state,
            hidden
        });
    }, [hidden]);

    function close() {
        setState({
            ...state,
            hidden: true
        });
    }

    return (
        <PopMenuWrapper onBlurCapture={() => close()} title={caption} hidden={state.hidden}>
            {props.children}
        </PopMenuWrapper>
    );
}

PopMenu.propTypes = {
    caption: PropTypes.string,
    callback: PropTypes.func,
    hidden: PropTypes.bool
};

export default PopMenu;
