export default class PopUp {
    constructor() {
        this.pp = document.createElement("div");
        this.lock = document.createElement("div");
        this.msg = document.createElement("p");
        this.btnContainer = document.createElement("div");

        this.lock.classList.add("lock-screen");
        this.lock.align = "center";

        this.msg.classList.add("pop-message");

        this.btnContainer.classList.add("d-flex", "flex-row", "w-100", "justify-content-around");

        this.pp.classList.add("pop-up");
        this.pp.appendChild(this.msg);
        this.pp.lastChild.after(this.btnContainer);
        this.pp.style.visibility = "visible";
        this.pp.style.opacity = "1";

        this.lock.appendChild(this.pp);
    }

    appendButton(text, handleClick) {
        let btn = document.createElement("button");
        btn.classList.add("btn", "btn-primary", "btn-rounded", "text-dark", "text-bold");
        btn.onclick = this.getDefaultAction(handleClick);
        btn.textContent = text || "OK";
        this.btnContainer.lastChild
            ? this.btnContainer.lastChild.after(btn)
            : this.btnContainer.appendChild(btn);
    }

    getDefaultAction(handle) {
        return function () {
            let handleFunction = handle;
            if (handleFunction && typeof handleFunction === "function") {
                handleFunction.call(this);
            }
            document.body.removeChild(this.parentElement.parentElement.parentElement);
        };
    }

    open(message, actions) {
        this.btnContainer.innerHTML = "";
        this.msg.textContent = message || "";
        if (Array.isArray(actions)) {
            actions.forEach((action) => {
                this.appendButton(action.text, action.handleClick);
            });
        } else {
            this.appendButton("OK");
        }
        document.body.lastChild.after(this.lock);
    }
}
