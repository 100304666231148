import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./styles.css";

function CardCredito(props) {
    function goToCheckout(e) {
       window.location = "/app/checkout?credito=" + props.credito; 
    }

    return (
        <div className="d-flex w-100 border-primary border-round card-credito  m-2">
            <div className="d-flex w-100 justify-content-between align-items-center flex-wrap">
                <div className="text-credito text-bold"> {
                    props.credito === 1 ? `${props.credito} Crédito` : `${props.credito} Créditos`
                    }</div>
                <div className=""> Neste pacote crédidos você tem {props.credito === 1 ? `${props.credito} conversão` : `${props.credito} conversões`} por <font className="cor-primaria">R$ {props.valorTotal.toLocaleString('pt-br', {minimumFractionDigits: 2})}</font></div>
                <div className="container-valor-unitario">
                    <div className="text-valor-unitario">R$ {props.valorUnit.toLocaleString('pt-br', {minimumFractionDigits: 2})}</div>
                    <div className="">Cada conversão</div>
                </div>
                <div className="">
                    <button className='btn btn-primary text-dark text-bold btn-rounded' onClick={goToCheckout}>
                        Comprar
                     </button>
                </div>
            </div>
        </div>
    );
}

CardCredito.propTypes = {
    credito: PropTypes.number,
    valorUnit: PropTypes.number,
    valorTotal: PropTypes.number
};

export default CardCredito;
