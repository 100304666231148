import React from "react";

import "./styles.css";

export default function Navbar(props) {
  return (
    <nav className="navbar top absolute">
      <h3 className="link brand-name">
        <a className="text-primary" href="/#header">
          {props.brandName}
        </a>
      </h3>
      <ul className="nav-links">
        {props.links &&
          props.links.map((link, key) => {
            return (
              <li
                key={key}
                className={
                  link.btnClass && !link.appendDefaultClasses ? "" : "link"
                }
              >
                <a
                  onClick={link.onClick}
                  className={`${props.theme || "dark"} ${link.btnClass || ""}`}
                  href={link.to}
                >
                  {link.name}
                </a>
              </li>
            );
          })}
        {props.children}
      </ul>
    </nav>
  );
}
