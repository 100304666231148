import React, { Component } from "react";
import CardCredito from "../../components/CardCredito";


export default class Creditos extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="flex-column w-100 align-items-center flex-wrap justify-content-center pt-5">
                    <div className="contact-form d-flex w-100 align-items-center flex-wrap justify-content-center">
                        <div className="d-flex w-100">
                           <h4 className="" style={{color: "#fad434"}}>COMPRE OS SEUS CRÉDITOS</h4> 
                        </div>
                        <div className='flex-wrap d-flex w-100 justify-content-between align-items-center'>
                            <div className='d-flex w-100'><CardCredito credito={1} valorUnit={2.10} valorTotal={2.10} /></div>
                            <div className='d-flex w-100'><CardCredito credito={50} valorUnit={1.80} valorTotal={90} /></div>
                            <div className='d-flex w-100'><CardCredito credito={100} valorUnit={1.50} valorTotal={150} /></div>
                            <div className='d-flex w-100'><CardCredito credito={200} valorUnit={1.20} valorTotal={240} /></div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
