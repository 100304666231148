import React, { useContext, useState } from "react";
import {
  BrowserRouter as Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import { createBrowserHistory } from "history";

import StoreContext from "../../components/Store/Context";
import Footer from "../../components/Footer";
import UserCredits from "../../components/UserCredits";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AccountCircle from "@mui/icons-material/AccountCircle";

import Conversor from "../Conversor";
import Profile from "../Profile";
import SectionContact from "../../components/SectionContact";
import Creditos from "../Creditos";
import Checkout from "../Checkout";
import Purchase from "../Purchase";
import Purchases from "../Purchases";

import ListPage from "../List";
import Cutplan from "../Cutplan";
import WebcutLogo from "../../assets/logo_webcut.png";

export default function Application(props) {
  const { window } = props;
  const drawerWidth = 240;
  const { setToken } = useContext(StoreContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [updateCreditsBar, setUpdateCreditsBar] = useState(0);
  const history = createBrowserHistory({ forceRefresh: true });
  let { path } = useRouteMatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const navItems = [
    {
      name: "Home",
      link: "/app",
    },
    {
      name: "Comprar Créditos",
      link: "/app/creditos",
    },
    {
      name: "Contato",
      link: "/app/contact",
    },
    {
      name: "Minhas Compras",
      link: "/app/purchases",
    },
    {
      name: "Converter XML promob",
      link: "/app/converter",
    },
    {
      name: "Criar Lista de Corte",
      link: "/app/service/0",
    },
    {
      name: "Perfil",
      link: "/app/profile",
    },
    {
      name: "Sair",
      link: "/",
    },
  ];
  function handleDrawerToggle() {
    setMobileOpen((prevState) => !prevState);
  }
  function handleLogout() {
    setToken("");
    localStorage.clear();
    history.push("/");
  }

  function navclick(e) {
    const element = e.target.parentElement.parentElement.parentElement;
    if (element.getAttribute("data-name") === "Sair") {
      handleLogout();
    }
    history.push(element.getAttribute("data-action"));
  }

  function navclickDirect(e) {
    history.push("/app/profile");
  }

  return (
    <React.Fragment>
      <Typography></Typography>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar component="nav" style={{ backgroundColor: "#2c354eff" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >
              <img src={WebcutLogo} height="50px"></img>
            </Typography>
            <UserCredits key={updateCreditsBar}></UserCredits>
          </Toolbar>
        </AppBar>
      </Box>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            <Typography variant="h6" sx={{ my: 2 }}>
              <img src={WebcutLogo} height="50px"></img>
            </Typography>
            <Divider />
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle
                onClick={navclickDirect}
                data-name="profile"
                data-action="/app/profile"
              />
            </IconButton>
            <Divider />
            <List>
              {navItems.map((item) => (
                <span onClick={navclick}>
                  <ListItem
                    key={item.name}
                    disablePadding
                    data-name={item.name}
                    data-action={item.link}
                  >
                    <ListItemButton sx={{ textAlign: "left" }}>
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                  </ListItem>
                </span>
              ))}
            </List>
            <Divider />
          </Box>
        </Drawer>
      </nav>
      <Switch>
        <Route exact path={path}>
          <ListPage stateChanger={setUpdateCreditsBar} />
        </Route>
        <Route path={`${path}/service/:id`}>
          <Cutplan />
        </Route>
        <Route path={`${path}/converter`}>
          <Conversor stateChanger={setUpdateCreditsBar} />
        </Route>
        <Route path={`${path}/profile`}>
          <Profile />
        </Route>
        <Route path={`${path}/contact`}>
          <SectionContact />
        </Route>
        <Route path={`${path}/creditos`}>
          <Creditos />
        </Route>
        <Route path={`${path}/checkout`}>
          <Checkout />
        </Route>
        <Route path={`${path}/purchase/:id`}>
          <Purchase />
        </Route>
        <Route path={`${path}/purchases`}>
          <Purchases />
        </Route>
      </Switch>
      <Footer title="Webcut" description=" todos os direitos reservados." />
    </React.Fragment>
  );
}
