import axios from "axios";
import PopUp from "./PopUp";
import { CONSTANTS } from "../config";
import Spinner from "./Spinner";

const popUp = new PopUp();

export async function requests(config) {
    let { path, params, queryString, options } = config;
    let { body, method, headers } = options || {};
    let token = localStorage.token;
    if (token) {
        token = token.replace(/"/gm, "");
        if (headers) {
            headers.authorization = `Bearer ${token}`;
        } else {
            headers = { authorization: `Bearer ${token}` };
        }
    }
    try {
        let url = `${CONSTANTS.host}${CONSTANTS.basePath}${path}/${params || ""}${
            queryString || ""
        }`;

        let opt = {
            method: method || "get",
            url,
            data: body || {},
            headers
        };

        let resp = await axios(opt);
        let data = resp.data;
        if (data) {
            return data;
        }
    } catch (error) {
        console.error(error);
        if (error.response.status == "401") {
            popUp.open("Sua sessão expirou, por favor faça login novamente.", [
                {
                    text: "OK",
                    handleClick: () => {
                        localStorage.clear();
                        window.location = "/";
                    }
                }
            ]);
        } else if (error.response.status == "403") {
            let user = JSON.parse(localStorage.getItem("user"));
            if (user.incompleteRegister) {
                popUp.open("Você não tem permissão para a solicitação!", [
                    {
                        text: "OK",
                        handleClick: () => {
                            window.location = "/app/profile";
                        }
                    }
                ]);
            }
        } else {
            return {
                error: error.response.data.error || error.response.data,
                status: error.response.status
            };
        }
    }
}

export function handleMail(name, email, message, subject) {
    const spinner = new Spinner();
    spinner.start();
    let options = {
        path: CONSTANTS.contact,
        options: {
            method: "post",
            body: {
                name,
                email,
                message,
                subject
            }
        }
    };
    requests(options)
        .then((res) => {
            spinner.finish();
            if (res && res.error) {
                if (res.status < 404) {
                    popUp.open(res.error);
                } else {
                    popUp.open("Falha na comunicação com o servidor, tente novamente mais tarde.");
                }
            } else {
                popUp.open("Mensagem Enviada com sucesso!\n Responderemos em breve.\n\nObrigado!");
            }
        })
        .catch((e) => {
            console.error(e);
            spinner.finish();
            popUp.open("Erro no envio da mensagem, por favor tente novamente.");
        });
}

export async function getAddress(cep) {
    try {
        let response = await axios.get(CONSTANTS.viacepHost.replace("$CEP", cep));
        if (response.data) {
            return response.data;
        }
    } catch (error) {
        console.error(error.response);
        return {};
    }
}
