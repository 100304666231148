import PopUp from "./PopUp";

export const popUp = new PopUp();
export { requests, handleMail, getAddress } from "./Requests";
export { dateMask, phoneMask, cepMask } from "./masks";

export const hideElement = (selector) => {
    if (selector) {
        let element = document.querySelector(selector);
        if (element) element && (element.style.display = "none");
        else throw new Error("Seletor Inválido");
    } else {
        throw new Error("Nenhum seletor enviado!");
    }
};

export const showElementAsBlock = (selector) => {
    if (selector) {
        let element = document.querySelector(selector);
        if (element) element && (element.style.display = "block");
        else new Error("Seletor inválido!");
    } else {
        throw new Error("Nenhum seletor enviado!");
    }
};

export const showElementAsFlex = (selector) => {
    if (selector) {
        let element = document.querySelector(selector);
        if (element) element && (element.style.display = "flex");
        else new Error("Seletor inválido!");
    } else {
        throw new Error("Nenhum seletor enviado!");
    }
};

export const fixAutofillInputs = (selector) => {
    let element = document.querySelector(selector);
    if (element) {
        element.classList.add("valid-input");
    }
};

export const generateUniqueId = (prefix) => {
    if (prefix) {
        return Date.now() + prefix.toString() + Math.round(Math.random() * 1000);
    } else {
        return Date.now() + "UUID" + Math.round(Math.random() * 1000);
    }
};
