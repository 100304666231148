import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

import { generateUniqueId } from "../../utils";

export function Check(props) {
    const _id = generateUniqueId("__Check");
    const { name, handleChange, checked } = props;
    return (
        <div className='__Check_wrapper'>
            <label htmlFor={_id}></label>
            <input
                title={props.title}
                onChange={handleChange}
                type='checkbox'
                name={name || "__Check_checkbox"}
                id={_id}
                checked={!!checked}
            />
            <div className='__Check_background'>
                <div className='__Check_circle'></div>
            </div>
        </div>
    );
}

Check.propTypes = {
    handleChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    checked: PropTypes.any
};

export default Check;
