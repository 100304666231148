const parameters = "/parameter";

export const CONSTANTS = {
  host: "//api.webcut.com.br",
  qaHost: "//api-export.webcut.com.br",
  basePath: "",
  qaBasePath: "",
  usersOperation: "/user",
  loginOperation: "/auth",
  forgotPassword: "/user/forgotPassword",
  resetPassword: "/user/resetPassword",
  activateUser: "/user/activate",
  parameters,
  fetchWoods: "/madeireira",
  fetchOptimizator: "/otimizador",
  fetchBookmarks: "/bookmarks",
  fetchFabricantes: parameters + "/brands",
  fetchOccupations: parameters + "/occupations",
  convert: "/execute",
  convertJson: "/execute-json",
  contact: "/contact",
  viacepHost: "https://viacep.com.br/ws/$CEP/json/",
  paymentRequest: "/payment",
  paymentGet: "/purchase",
  creditsGet: "/credits",
  paymentsGet: "/purchases",
};
