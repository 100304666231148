import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import querystring from "query-string";

import MaterialInput from "../../components/MaterialInput";
import Navbar from "../../components/Navbar";


import { requests, popUp } from "../../utils";
import { CONSTANTS } from "../../config";

import Spinner from "../../utils/Spinner";

import "./styles.css";

function initialState() {
  return {
    password: "",
    confirmPassword: "",
    message: "",
    isHiddenMessage: true,
    hasRightParams: false,
    params: {},
  };
}

export default function SectionContact(props) {
  const [state, setState] = useState(initialState);
  const history = useHistory();
  const spinner = new Spinner();

  function handleChange(event) {
    const { value, name } = event.target;

    setState({
      ...state,
      [name]: value,
    });
  }

  function handleReset(e) {
    e.preventDefault();
    if (state.password && state.confirmPassword) {
      if (state.password === state.confirmPassword) {
        spinner.start();
        requests({
          path: CONSTANTS.resetPassword,
          options: {
            method: "post",
            body: {
              token: state.params.t,
              email: state.params.e,
              password: state.password,
            },
          },
        })
          .then((res) => {
            spinner.finish();
            if (res && res.error) {
              if (res.status < 405) {
                popUp.open(res.error);
              } else {
                popUp.open("Erro na comunicação com o servidor");
              }
            } else {
              localStorage.setItem("user", "");
              history.push("/");
            }
          })
          .catch((e) => {
            spinner.finish();
            console.error(e);
            popUp.open(
              "Erro ao tentar fazer o reset de senha, por favor tente novamente"
            );
          });
      } else {
        popUp.open("A senha e a confirmação de senha devem ser idênticas.");
      }
    } else {
      popUp.open("Por favor, preencha senha e confirmação para prosseguir");
    }
  }

  useEffect(() => {
    let params = querystring.parse(props.location.search);
    if (Object.keys(params).length > 0) {
      setState({
        ...state,
        params,
      });
    } else {
      history.push("/");
    }
  }, [props.location.search]);

  return (
    <section
      id="recovery"
      className="d-flex vh-100 w-100 justify-content-center align-items-center recovery-container pv-3"
    >
      <Navbar
        brandName="Webcut"
        theme="light"
        links={[
          {
            name: "Home",
            to: "/#head",
            onClick: function () {
              localStorage.clear();
              history.push("/");
            },
          },
        ]}
      />
      <form
        onSubmit={handleReset}
        action="submit"
        className="recovery-form ph-1 text-center mt-5"
      >
        <h4 className="w-100 text-left text-light text-bold text-center">
          Alterar Senha
        </h4>
        <MaterialInput
          id="pass"
          name="password"
          type="password"
          placeholder="Nova Senha"
          value={state.password}
          onChange={handleChange}
        />
        <MaterialInput
          id="confirmPass"
          name="confirmPassword"
          type="password"
          placeholder="Confirmação"
          value={state.confirmPassword}
          onChange={handleChange}
        />
        <button className="mv-4 btn btn-primary btn-rounded text-dark text-heavy align-self-center">
          Alterar
        </button>
        <p hidden={state.isHiddenMessage} className="text-light pt-2 text-bold">
          {state.message}
        </p>
      </form>
    </section>
  );
}
