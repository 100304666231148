import React from "react";
import { Wrapper } from "./styles";
import ReactTooltip from "react-tooltip";

const generateHeaders = (data) => {
    let ths = [];
    data.forEach((item, index) => {
        const { label, width, textAlign, children } = item;
        ths.push(
            <th
                data-tip={children ? item.alt : ""}
                key={index}
                style={{ textAlign: `${textAlign || "left"}` }}
                width={width || "auto"}>
                {children ? children : label}
                {item.alt && !children && (
                    <>
                        <i
                            data-tip={item.alt}
                            className='fa fa-info-circle'
                            style={{ marginLeft: "5px" }}
                            aria-hidden='true'></i>
                    </>
                )}
            </th>
        );
    });
    return React.createElement("tr", {
        [`data-${data.htmlDataAttributeName || "meta"}`]: data.htmlDataAttribute || "",
        children: ths
    });
};

const generateRows = (data, map) => {
    let rows = [];
    data.forEach((item, i) => {
        let tds = [];
        const { handleClick } = item;
        map.forEach((mapKey, index) => {
            const { field, textAlign, minWidth } = mapKey;
            tds.push(
                React.createElement("td", {
                    key: index,
                    style: { textAlign: textAlign || "left", minWidth: minWidth || "" },
                    children: item[field] || "-"
                })
            );
        });
        rows.push(
            React.createElement("tr", {
                [`data-${item.htmlDataAttributeName || "meta"}`]:
                    String(item.htmlDataAttribute) || "",
                key: i,
                children: tds,
                onClick: handleClick || function () {}
            })
        );
    });
    return rows;
};

export function SimpleTable(props) {
    const { columns, rows, maxHeight, minHeight, bordered, headerColor, borderLines } = props;
    return (
        <Wrapper
            borderLines={borderLines}
            headerColor={headerColor}
            bordered={bordered}
            maxHeight={maxHeight}
            minHeight={minHeight}>
            <ReactTooltip />
            <table>
                <thead>{generateHeaders(columns)}</thead>
                <tbody>{generateRows(rows, columns)}</tbody>
            </table>
        </Wrapper>
    );
}

SimpleTable.propTypes = {};

export default SimpleTable;
