import React, { Component, useState, useEffect } from "react";
import CardCredito from "../../components/CardCredito";
import MaterialInput from "../../components/MaterialInput";
import TextArea from "../../components/TextArea";
import TextField from "@mui/material/TextField";

import { Wrapper } from "./style";

import ReactTooltip from "react-tooltip";

import {
  requests,
  popUp,
  fixAutofillInputs,
  dateMask,
  phoneMask,
  cepMask,
} from "../../utils";
import Spinner from "../../utils/Spinner";
import { CONSTANTS } from "../../config";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

function Checkout(props) {
  const INITIAL_STATE = {
    name: "",
    cpf: "",
    credito: 0,
    valorUnit: 0,
    ValorTotal: 0,
    isLogging: false,
  };
  const [state, setState] = useState(INITIAL_STATE);
  const [userState, setUserState] = useState({
    name: "",
    cpf: "",
  });
  function onChangeCredito(e) {
    let { name: field, value } = e.target;
    setState({ ...state, credito: value });
  }
  function onChange(e) {
    let { value, name: field } = e.target;
    setUserState({
      ...userState,
      [field]: value,
    });
  }
  function onSubmit(e) {
    e.preventDefault();
    if (!userState.cpf) {
      popUp.open(
        "Por favor, preencha o CPF em seu perfil antes de realizar uma compra!",
      );
      return;
    }
    let spinner = new Spinner();
    spinner.start();
    let body = { ...userState };
    body.credito = state.credito;
    requests({
      path: CONSTANTS.paymentRequest,
      options: {
        method: "POST",
        body,
      },
    })
      .then((res) => {
        spinner.finish();
        if (res && res.error) {
          if (res.status < 404) {
            popUp.open(res.error);
          } else {
            popUp.open("Erro na comunicação com o servidor.");
          }
        } else {
          popUp.open("Pedido criado com sucesso!", [
            {
              text: "OK",
              handleClick: () => {
                window.location = `/app/purchase/${res.txid}`;
              },
            },
          ]);
        }
      })
      .catch((e) => {
        spinner.finish();
        popUp.open(
          "Erro desconhecido!\n Por favor, tente novamente mais tarde.",
        );
      });
  }
  function setUnitCredit() {
    const creditoFromState = state.credito;
    if (creditoFromState >= 1 && creditoFromState < 50) {
      setState({ ...state, valorUnit: 2.1 });
      return;
    }
    if (creditoFromState >= 50 && creditoFromState < 100) {
      setState({ ...state, valorUnit: 1.8 });
      return;
    }
    if (creditoFromState >= 100 && creditoFromState < 200) {
      setState({ ...state, valorUnit: 1.5 });
      return;
    }
    if (creditoFromState >= 200) {
      setState({ ...state, valorUnit: 1.2 });
      return;
    }
  }
  useEffect(() => {
    setUnitCredit();
  }, [state.credito]);
  useEffect(() => {
    function getUser() {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        if (!user.cpf) {
          popUp.open(
            "Antes de realizar uma compra, por favor atualize seu CPF!",
            [
              {
                name: "OK",
                handleClick: () => {
                  window.location = "/app/profile";
                },
              },
            ],
          );
        }
        let spin = new Spinner();
        spin.start();
        setUserState({ ...userState, name: user.name, cpf: user.cpf });
        spin.finish();
      }
    }

    const queryParams = new URLSearchParams(window.location.search);
    const unitValueParam = queryParams.get("credito") || 0;
    setState({ ...state, credito: unitValueParam });
    setUnitCredit(state.credito);
    getUser();
  }, []);
  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1, p: 4, pt: 15 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <div className="__Card_component checkout">
              <form
                action="submit"
                className="contact-form ph-1 text-center d-flex flex-column justify-content-center align-items-center"
                onSubmit={onSubmit}
              >
                <h5 className="input-wrapper-checkout text-left text-light">
                  Dados
                </h5>
                <TextField
                  margin="normal"
                  fullWidth
                  id="name"
                  name="name"
                  variant="outlined"
                  label="Nome"
                  value={userState.name}
                  onChange={onChange}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="cpf"
                  variant="outlined"
                  name="cpf"
                  label="CPF"
                  value={userState.cpf}
                  onChange={onChange}
                />
                <h5 className="input-wrapper-checkout text-left text-light">
                  Créditos
                </h5>
                <TextField
                  margin="normal"
                  fullWidth
                  id="credito"
                  name="credito"
                  label="Quantidade"
                  value={state.credito.toString()}
                  onChange={onChangeCredito}
                />
                <button className="mv-4 btn btn-primary btn-rounded text-dark text-heavy">
                  Confirmar Compra
                </button>
              </form>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="__Card_component d-flex checkout w-100 flex-wrap">
              <div className="d-flex justify-content-center w-100">
                <h5 className="input-wrapper-checkout-details text-center text-light">
                  Resumo da Compra
                </h5>
              </div>
              <div className="d-flex justify-content-center w-100">
                <Wrapper
                  borderLines={true}
                  headerColor="#000"
                  bordered={"solid red"}
                  maxHeight={"600px"}
                  minHeight={"200px"}
                >
                  <ReactTooltip />
                  <table>
                    <thead>
                      <th>Item</th>
                      <th>Valor</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Crédito Avulso
                          <>
                            <i
                              data-tip={
                                "Valor unitário depende da quantidade de créditos escolhida"
                              }
                              className="fa fa-info-circle"
                              style={{ marginLeft: "5px" }}
                              aria-hidden="true"
                            ></i>
                          </>
                        </td>
                        <td>
                          R${" "}
                          {state.valorUnit.toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>Quantidade de Créditos</td>
                        <td>
                          R${" "}
                          {state.credito.toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td>
                          R${" "}
                          {(state.valorUnit * state.credito).toLocaleString(
                            "pt-br",
                            { minimumFractionDigits: 2 },
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Wrapper>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Checkout;
