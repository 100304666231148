import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./styles.css";

import eye from "../../assets/eye-solid-light.svg";
import eyeSlash from "../../assets/eye-slash-solid-light.svg";

function MaterialInput(props) {
    const [type, setType] = useState("text");
    const focus = (e) => {
        e.target.parentElement.querySelector("label").classList.add("focused-label");
    };

    const blur = (e) => {
        let value = e.target.value;
        if (value) {
            e.target.parentElement.querySelector("label").classList.add("valid-input");
        }
        e.target.parentElement.querySelector("label").classList.remove("focused-label");
    };

    useEffect(() => {
        if (props.type) {
            setType(props.type);
        }
    }, [props.type]);

    return (
        <div hidden={props.hidden || false} className={`${props.className || ""} input-wrapper`}>
            <input
                hidden={props.hidden || false}
                disabled={props.disabled || false}
                maxLength={props.maxLength || "200"}
                required={props.required || false}
                autoComplete={props.autoComplete || "off"}
                className='material-input'
                type={type}
                name={props.name || ""}
                value={props.value || ""}
                onChange={props.onChange || function () {}}
                onFocus={focus}
                onBlur={blur}
                onInput={focus}
                style={{ ...props.style, paddingRight: props.type === "password" ? "25px" : "" }}
            />
            <label className='label'>{props.placeholder}</label>
            <span className='bar'></span>
            {props.type === "password" && (
                <a
                    className='eye-button'
                    onClick={(e) => {
                        e.preventDefault();
                        setType(type === "password" ? "text" : "password");
                    }}>
                    <img height='15px' src={type === "password" ? eye : eyeSlash} alt='' />
                </a>
            )}
        </div>
    );
}

MaterialInput.propTypes = {
    hidden: PropTypes.bool,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    autoComplete: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
};

export default MaterialInput;
