import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import {
  requests,
  popUp,
  fixAutofillInputs,
  dateMask,
  phoneMask,
  cepMask,
} from "../../utils";
import { CONSTANTS } from "../../config";

function UserCredits(props) {
  const [userState, setUserState] = useState({
    credit: 0,
    credit_free: 0,
  });
  function modalPayd() {
    popUp.open(
      "Seus créditos de converdsão extra adquiridos atráves do meio de pagamento e NÃO expiram.",
    );
  }

  function modalFree() {
    popUp.open(
      "Seus créditos grátis que é renovado todo primeiro dia útil do mes.",
    );
  }

  useEffect(() => {
    function getUserCreditRequest() {
      requests({
        path: CONSTANTS.creditsGet,
        options: {
          method: "GET",
        },
      })
        .then((res) => {
          if (res && res.error) {
            if (res.status < 404) {
              popUp.open(res.error);
            } else {
              popUp.open("Erro na comunicação com o servidor.");
            }
          } else {
            console.log(res);
            setUserState({
              ...userState,
              credit: res.credit,
              credit_free: res.credit_free,
            });
          }
        })
        .catch((e) => {
          popUp.open(
            "Erro desconhecido!\n Por favor, tente novamente mais tarde.",
          );
        });
    }
    getUserCreditRequest();
  }, []);

  return (
    <>
      <Box sx={{ display: { xs: "block", sm: "block" } }}>
        <Stack spacing={4} direction="row" sx={{ color: "white" }}>
          <Badge
            color="primary"
            badgeContent={userState.credit}
            showZero
            onClick={modalPayd}
          >
            <RequestQuoteIcon />
          </Badge>
          <Badge
            color="primary"
            badgeContent={userState.credit_free}
            showZero
            onClick={modalFree}
          >
            <CardGiftcardIcon />
          </Badge>
        </Stack>
      </Box>
    </>
  );
}

UserCredits.propTypes = {};

export default UserCredits;
