export function phoneMask(value) {
    var str = value.replace(/[^\d]+/g, "");
    if (str.length > 2) {
        str = "(" + str.substring(0, 2) + ") " + str.substring(2, str.length);
    }
    if (str.length > 9) {
        str = str.substring(0, 9) + "-" + str.substring(9, str.length);
    }
    if (str.length > 11) {
        str = value.replace(/[^\d]+/g, "");
        str =
            "(" +
            str.substring(0, 2) +
            ") " +
            str.substring(2, str.length - 4) +
            "-" +
            str.substring(str.length - 4);
    }
    if (str.length > 14) {
        str = value.substring(0, 15);
        str = str.replace(/-/g, "");
        str = str.substring(0, str.length - 4) + "-" + str.substring(str.length - 4);
    }
    return str;
}

export function dateMask(value) {
    var str = value.replace(/[^\d]+/g, "");
    if (str.length > 2) {
        str = str.substring(0, 2) + "/" + str.substring(2, str.length);
    }
    if (str.length > 4) {
        str = str.substring(0, 4) + "/" + str.substring(4, str.length);
    }
    if (str.length > 6) {
        str = value.replace(/[^\d]+/g, "");
        str = str.substring(0, 2) + "/" + str.substring(2, 4) + "/" + str.substring(4, str.length);
    }
    if (str.length > 9) {
        str = str.substring(0, 10);
    }
    return str;
}

export function cepMask(value) {
    var str = value.replace(/[^\d]+/g, "");
    if (str.length > 5) {
        str = str.substring(0, 5) + "-" + str.substring(5, str.length);
    }
    if (str.length > 7) {
        str = value.replace(/[^\d]+/g, "");
        str = str.substring(0, 5) + "-" + str.substring(5, str.length);
    }
    if (str.length > 8) {
        str = str.substring(0, 9);
    }
    return str;
}

export function isValidCPF(value) {
    // Se não for string, o CPF é inválido
    if (typeof value !== 'string') {
        return false;
    }

    // Remove todos os caracteres que não sejam números
    value = value.replace(/[^\d]+/g, '');

    // Se o CPF não tem 11 dígitos ou todos os dígitos são repetidos, o CPF é inválido
    if (value.length !== 11 || !!value.match(/(\d)\1{10}/)) {
        return false;
    }
    
    // Transforma de string para number[] com cada dígito sendo um número no array
    const digits = value.split('').map(el => +el);

    // Função que calcula o dígito verificador de acordo com a fórmula da Receita Federal
    function getVerifyingDigit(arr) {
        const reduced = arr.reduce( (sum, digit, index)=>(sum + digit * (arr.length - index + 1)), 0 );
        return (reduced * 10) % 11 % 10;
    }

    // O CPF é válido se, e somente se, os dígitos verificadores estão corretos
    return getVerifyingDigit(digits.slice(0, 9)) === digits[9]
        && getVerifyingDigit(digits.slice(0, 10)) === digits[10];
}
