import styled from "styled-components";

export const Aside = styled.aside`
    min-height: 100vh;
    display: flex;
    width: var(--min-width);
    position: absolute;
    left: ${(props) => (props.show ? "0px" : "-360px")};
    transition: left ease-in-out 300ms;
    z-index: 1;
`;

export const ActionsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;
