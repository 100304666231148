import React, { useState, useCallback } from "react";
import "./styles.css";

import PropTypes from "prop-types";
import { useEffect } from "react";
import {
    ImagePreviewWrapper,
    ImagePreviewContainerHeader,
    Thumbnail,
    ThumbnailContainer
} from "./styles";

import ReactTooltip from "react-tooltip";

export function ImagePreview({
    maxHeight = 200,
    width,
    height,
    hidden,
    caption,
    img,
    callback,
    isDefault,
    isSelectable,
    handleSelectedBorders,
    selectedBorders
}) {
    const [state, setState] = useState({
        width: "10px",
        height: "10px",
        originalHeight: 0,
        originalWidth: 0,
        hidden,
        rotate: false,
        isDefault,
        isSelectable
    });

    const [borders, setBorders] = useState({
        bord_dir: false,
        bord_esq: false,
        bord_sup: false,
        bord_inf: false
    });

    const handleBorders = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let { name, value, id } = e.target;
        if (name) {
            setBorders({
                ...borders,
                [name]: !borders[name]
            });
        }
        if (id === "thumbnail") {
            if (borders.bord_dir && borders.bord_esq && borders.bord_sup && borders.bord_inf) {
                setBorders({
                    bord_dir: false,
                    bord_esq: false,
                    bord_sup: false,
                    bord_inf: false
                });
                handleSelectedBorders("bord_dir", false);
                handleSelectedBorders("bord_esq", false);
                handleSelectedBorders("bord_sup", false);
                handleSelectedBorders("bord_inf", false);
            } else {
                setBorders({
                    bord_dir: true,
                    bord_esq: true,
                    bord_sup: true,
                    bord_inf: true
                });
                handleSelectedBorders("bord_dir", true);
                handleSelectedBorders("bord_esq", true);
                handleSelectedBorders("bord_sup", true);
                handleSelectedBorders("bord_inf", true);
            }
        } else {
            handleSelectedBorders(name, !borders[name]);
        }
    };

    useEffect(() => {
        setState({
            ...state,
            hidden: hidden
        });
    }, [hidden]);

    useEffect(() => {
        if (selectedBorders) {
            setBorders({
                bord_dir: selectedBorders.bord_dir,
                bord_esq: selectedBorders.bord_esq,
                bord_sup: selectedBorders.bord_sup,
                bord_inf: selectedBorders.bord_inf
            });
        }
    }, [selectedBorders]);

    function setProportionalWidth(width, height, isDefault) {
        let rotate;
        let aspect;
        let newWidth;
        let newHeight;

        if (isDefault) {
            rotate = false;
            newWidth = 45;
            newHeight = maxHeight;
        } else {
            rotate = width < height;
            aspect = !rotate ? height / width : width / height;
            if (aspect < 0.25) {
                aspect = 0.25;
            }
            newWidth = rotate ? aspect * maxHeight : maxHeight;
            newHeight = rotate ? maxHeight : aspect * maxHeight;
        }

        return {
            rotate,
            width: newWidth + "px",
            height: newHeight + "px",
            originalHeight: !isNaN(height) ? height : 0,
            originalWidth: !isNaN(width) ? width : 0
        };
    }

    function close() {
        setState({
            ...state,
            hidden: true
        });
        callback(true);
    }

    return (
        <ImagePreviewWrapper
            id='ipw'
            onClickCapture={function (e) {
                if (e.target.tagName === "SPAN" || e.target.tagName === "SECTION") {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }}
            hidden={state.hidden}>
            <ReactTooltip />
            <ImagePreviewContainerHeader>
                <span>{caption}</span>
                <button onClick={close}>
                    <small className='text-bold'>&#9587;</small>
                </button>
            </ImagePreviewContainerHeader>
            <ThumbnailContainer>
                <span className='span_width'>{width + " L"}</span>
                <button
                    className='hovered-button'
                    style={{
                        height: "20px",
                        width: "100%",
                        cursor: "pointer",
                        border: "none",
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "10pt",
                        outline: "none"
                    }}
                    name='bord_sup'
                    data-tip='Borda superior'
                    onClick={handleBorders}>
                    L1
                </button>
                <button
                    style={{
                        height: "100%",
                        width: "20px",
                        position: "absolute",
                        right: "-20px",
                        top: "0",
                        outline: "none",
                        cursor: "pointer",
                        border: "none",
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "10pt",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}
                    name='bord_dir'
                    data-tip='Borda direita'
                    onClick={handleBorders}>
                    C2
                </button>
                <div
                    style={{
                        width: setProportionalWidth(width, height, isDefault).width,
                        height: setProportionalWidth(width, height, isDefault).height
                    }}>
                    <Thumbnail
                        borderTop={borders.bord_sup}
                        borderBottom={borders.bord_inf}
                        borderLeft={borders.bord_esq}
                        borderRight={borders.bord_dir}
                        img={img}>
                        <div
                            data-tip='Selecionar todas as bordas'
                            id='thumbnail'
                            onClickCapture={handleBorders}></div>
                    </Thumbnail>
                </div>
                <button
                    style={{
                        height: "100%",
                        width: "20px",
                        position: "absolute",
                        left: "-20px",
                        top: "0",
                        outline: "none",
                        cursor: "pointer",
                        border: "none",
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "10pt",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}
                    name='bord_esq'
                    data-tip='Borda esquerda'
                    onClick={handleBorders}>
                    C1
                </button>
                <button
                    className='hovered-button'
                    style={{
                        height: "20px",
                        width: "100%",
                        cursor: "pointer",
                        border: "none",
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "10pt",
                        outline: "none"
                    }}
                    name='bord_inf'
                    data-tip='Borda inferior'
                    onClick={handleBorders}>
                    L2
                </button>
                <span className='span_height'>{height + " C"}</span>
            </ThumbnailContainer>
        </ImagePreviewWrapper>
    );
}

ImagePreview.propTypes = {
    img: PropTypes.string,
    caption: PropTypes.string,
    maxHeight: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    callback: PropTypes.func
};

export default ImagePreview;
