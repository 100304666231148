export class Sheet {
    constructor(model = {}) {
        let {
            quant,
            esp,
            larg,
            comp,
            chapa,
            funcao,
            material,
            rotaciona,
            fita,
            obs,
            bord_dir,
            bord_esq,
            bord_sup,
            bord_inf,
            product,
            productId,
            selected
        } = model;
        this.selected = selected;
        this.quant = quant || 0;
        this.esp = esp || 0;
        this.larg = larg || 0;
        this.comp = comp || 0;
        this.obs = obs || "";
        this.chapa = chapa || "";
        this.funcao = funcao || "";
        this.material = material || "";
        this.rotaciona = rotaciona || false;
        this.fita = fita || "";
        this.bord_dir = bord_dir || false;
        this.bord_esq = bord_esq || false;
        this.bord_sup = bord_sup || false;
        this.bord_inf = bord_inf || false;
        this.showPreview = false;
        this.showOptions = false;
        this.product = product || {};
        this.productId = productId || "";
    }
}

class JsonModelSheet {
    constructor(model = {}) {
        let { label, value, index } = model;
        this.label = label || "";
        this.value = value || "";
        this.index = index || 0;
    }
}

export class CutPlanModel {
    constructor(model = {}) {
        let { sheetId, clientName, date, type, typeId, modelJsonSheet, sheet } = model;
        this.sheetId = sheetId || "";
        this.clientName = clientName || "";
        this.date = date || new Date();
        this.type = type || "";
        this.typeId = typeId || "";
        this.modelJsonSheet = modelJsonSheet || [new JsonModelSheet()];
        this.sheet = sheet || [new Sheet()];
    }
}
