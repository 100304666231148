import { requests } from "../../utils";
import { CONSTANTS } from "../../config";

export function filter(array, field, value, firstResult = true) {
  if (field && value) {
    if (array.length > 0 && Array.isArray(array[0][field])) {
      if (firstResult) {
        return array.find((wood) => wood[field].includes(value));
      } else {
        return array.filter((wood) => wood[field].includes(value));
      }
    }
    if (firstResult) {
      return array.find((wood) => wood[field] === value);
    } else {
      return array.filter((wood) => wood[field] === value);
    }
  }
}

export function filterWoodsData(woods) {
  let cities = [];
  if (woods.length > 0) {
    for (let wood of woods) {
      let composed = `${wood.city}-(${wood.state})`;
      !cities.includes(composed) && cities.push(composed);
    }
  }
  return {
    cities,
  };
}

export function fetchWoods() {
  return requests({
    path: CONSTANTS.fetchWoods,
  });
}

export function fetchOptimizators() {
  return requests({
    path: CONSTANTS.fetchOptimizator,
  });
}

export function fetchBookmarks() {
  return requests({
    path: CONSTANTS.fetchBookmarks,
  });
}

export const INITIAL_STATE = {
  droppedFile: undefined,
  radios: {
    wood: false,
    optimizator: false,
    sheet: true,
  },
  hideCompany: true,
  hideOptimizators: true,
  selectedOptimizator: "",
  company: {},
  optimizators: [],
  woods: [],
  cities: [],
  filteredWoods: [],
  selectedState: "",
  selectedCity: "",
  selectedWood: "",
  modelSheet: null,
  modelJsonSheet:null,
  isBlockConvertButton: false,
  resetStates: false,
  resetWoods: false,
  resetOptimizators: false,
  bookmarks: [],
  bookmarkList: {
    madeireiras: [],
    otimizadores: [],
  },
};
