import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./styles.css";

function InputSearch(props) {
  const INITIAL_STATE = {
    options: [],
    suggestions: [],
    isHiddenSuggestions: true,
    value: "",
    cursor: 0,
  };
  const [state, setState] = useState(INITIAL_STATE);

  function toggleSugestions(value) {
    setState({
      ...state,
      isHiddenSuggestions: value,
    });
  }

  function handleKeyDown(e) {
    const { cursor, suggestions } = state;
    const element = e.target.parentElement.querySelector(
      ".__component_input_search_item_active"
    );
    if (e.keyCode === 13) {
      e.preventDefault();
      element.click();
    } else if (e.keyCode === 27) {
      setState({
        ...state,
        isHiddenSuggestions: true,
      });
    } else if (e.keyCode === 38 && cursor > 0) {
      setState({
        ...state,
        cursor: cursor - 1,
      });
      if (element) element.parentElement.scrollTop = element.offsetTop - 40;
    } else if (e.keyCode === 40 && cursor < suggestions.length - 1) {
      setState({
        ...state,
        cursor: cursor + 1,
        isHiddenSuggestions: false,
      });
      document.activeElement.nextElementSibling.scrollTop = element.offsetTop;
    }
  }

  function handleChange(event) {
    const value = event.target.value;
    let suggestions;
    if (value) {
      suggestions = state.options.filter((option) =>
        option.toLowerCase().includes(state.value.toLowerCase())
      );
      if (suggestions.length === 0) {
        suggestions = state.options;
      }
    } else suggestions = state.options;
    setState({
      ...state,
      value,
      suggestions,
      isHiddenSuggestions: false,
    });
    if (props.handleChange) {
      props.handleChange(value);
    }
  }

  function handleSelected(e) {
    e.preventDefault();
    let value = e.target.textContent;
    setState({
      ...state,
      value,
      isHiddenSuggestions: true,
    });
    if (props.handleSelected) {
      props.handleSelected(value);
    }
  }

  useEffect(() => {
    if (props.options) {
      setState({
        ...state,
        options: props.options,
        suggestions: props.options,
      });
    }
  }, [props.options]);

  useEffect(() => {
    if (props.reset) {
      setState({
        ...state,
        value: "",
        isHiddenSuggestions: true,
      });
    }
  }, [props.reset]);

  return (
    <div
      hidden={props.hidden || false}
      className="__component_input_search_wrapper"
      onBlur={() => {
        if (!state.isHiddenSuggestions) toggleSugestions(true);
      }}
    >
      <input
        disabled={props.disabled || false}
        placeholder={props.placeholder || "Buscar"}
        autoComplete="off"
        className={
          props.disabled
            ? "__component_input_search_input __component_input_search_disabled"
            : "__component_input_search_input"
        }
        value={state.value}
        type="text"
        name={props.name}
        id={props.id || ""}
        onChange={handleChange}
        onClick={() => toggleSugestions(!state.isHiddenSuggestions)}
        onKeyDown={handleKeyDown}
        title={props.title || ""}
      />
      <ul
        className="__component_input_search_list"
        hidden={state.isHiddenSuggestions}
      >
        {state.suggestions.map((option, index) => (
          <li
            className={
              state.cursor === index
                ? "__component_input_search_item __component_input_search_item_active"
                : "__component_input_search_item"
            }
            key={index}
            onClick={handleSelected.bind(this)}
            onMouseDown={handleSelected.bind(this)}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
}

InputSearch.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSelected: PropTypes.func.isRequired,
  name: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  reset: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

export default InputSearch;
