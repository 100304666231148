import styled from "styled-components";

export const Wrapper = styled.div`
    ${(props) => (props.bordered ? "border: solid var(--primary);" : "")}
    border-radius: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-height: ${(props) => props.maxHeight};
    min-height: ${(props) => props.minHeight};
    ${(props) => (props.maxHeight ? "overflow: auto;" : "overflow: none;")}

    &::-webkit-scrollbar-thumb {
        background-color: var(--primary);
        border-radius: 6px;
        border: 3px solid var(--primary);
    }

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 6px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
        font-weight: bold;
        white-space: nowrap;
        padding: 1rem;
    }

    th {
        ${(props) => (props.headerColor ? `color: ${props.headerColor};` : "")}
    }

    tr {
        ${(props) => (props.borderLines ? "border-bottom: dotted thin;" : "")}
    }

    td,
    th {
        border: none;
        text-align: left;
        padding-left: 5px;
    }

    tbody > tr:hover {
        background-color: rgba(200, 200, 200, 0.3);
        cursor: pointer;
    }

    th {
        font-size: 10pt;
        position: relative;
    }

    thead {
        border-bottom: solid thin;
        padding-bottom: 1px;
    }

    tbody > tr:nth-child(odd) {
        background-color: rgba(200, 200, 200, 0.3);
    }
`;

