import styled from "styled-components";

export const ImagePreviewWrapper = styled.section`
    top: 0px;
    right: 40px;
    padding: 1.8rem;
    background-color: #f5f5f5;
    border-radius: 0.4rem;
    position: absolute;
    z-index: 10;
    border: dashed 1px black;
    box-shadow: 10px 10px 10px #dddddd;
    cursor: default;
`;

export const ImagePreviewContainerHeader = styled.div`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.3rem;
    margin-top: -20px;
    color: black;

    span {
        font-weight: bold;
        margin-right: 1rem;
    }

    button {
        border: none;
        outline: none;
        border-radius: 50rem;
        width: 30px !important;
        height: 30px !important;
        min-width: auto !important;
        color: red;
        padding: 0px;
        margin-right: -20px;
        background: transparent;
    }
`;

export const ThumbnailContainer = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;

    &::nth-child(2) {
        overflow: hidden;
    }
`;

export const Thumbnail = styled.section`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-image: url(${(props) => props.img});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-style: solid;
    border-width: 3px;
    border-bottom-color: ${(props) => (props.borderBottom ? "red" : "transparent")};
    border-top-color: ${(props) => (props.borderTop ? "red" : "transparent")};
    border-left-color: ${(props) => (props.borderLeft ? "red" : "transparent")};
    border-right-color: ${(props) => (props.borderRight ? "red" : "transparent")};

    & > div {
        width: 60%;
        height: 60%;
        border: dashed 2px #0000005c;
        cursor: pointer;
        outline-color: white;
        outline-offset: 2px;
        outline-style: dashed;
        outline-width: 1px;
    }

    & > div:hover {
        border-color: red;
        border-width: 3px;
        background-color: rgba(255, 0, 0, 0.3);
    }
`;
