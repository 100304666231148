import React, { useState, useContext, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import "./styles.css";

import StoreContext from "../../components/Store/Context";
import madeireira from "../../assets/madeireira.png";
import otimizador from "../../assets/otimizador2.png";

import DragNDrop from "../../components/DragNDrop";
import Card from "../../components/Card";
import SelectSearch from "../../components/SelectSearch";
import { useHistory, Link } from "react-router-dom";
import { popUp, requests } from "../../utils";
import {
  fetchOptimizators,
  fetchWoods,
  filter,
  filterWoodsData,
  INITIAL_STATE,
  fetchBookmarks,
} from "./functions";

import Spinner from "../../utils/Spinner";
import { CONSTANTS } from "../../config";

import { saveAs } from "file-saver";

export default function Conversor(props) {
  const { t } = useTranslation();
  const [state, setState] = useState(INITIAL_STATE);
  const { token } = useContext(StoreContext);
  const history = useHistory();
  const spinner = new Spinner();
  const { addToast } = useToasts();

  function handleSelectedBookmark(value) {
    let selectedBookmark = filter(state.bookmarks, "name", value, true);
    if (selectedBookmark.phone) {
      let isBookmarked = state.bookmarks.find(
        (bk) => bk._id === selectedBookmark._id,
      );
      setState({
        ...state,
        selectedBookmark,
        selectedWood: selectedBookmark.name,
        hideCompany: false,
        company: {
          ...selectedBookmark,
          fullAddress: `${selectedBookmark.street}, ${selectedBookmark.number} ${selectedBookmark.neighborhood} ${selectedBookmark.city}-${selectedBookmark.state}`,
          isBookmarked: !!isBookmarked,
        },
        selectedOptimizator: "",
        hideOptimizators: true,
        radios: {
          wood: true,
          optimizator: false,
          sheet: false,
        },
        modelSheet: selectedBookmark.modelSheet,
      });
    } else {
      let isBookmarked = state.bookmarks.find(
        (bk) => bk._id === selectedBookmark._id,
      );
      setState({
        ...state,
        selectedBookmark: selectedBookmark,
        company: "",
        hideCompany: true,
        hideOptimizators: false,
        selectedWood: "",
        selectedOptimizator: {
          ...selectedBookmark,
          isBookmarked: !!isBookmarked,
        },
        radios: {
          wood: false,
          optimizator: true,
          sheet: false,
        },
        modelSheet: selectedBookmark.modelSheet,
        resetWoods: true,
      });
    }
  }

  function handleAddBookmark(e) {
    e.preventDefault();
    let { id, name } = e.target.parentElement;
    let body = {};
    if (name === "bookmarks-madeireira") {
      let isRemove = state.bookmarkList.madeireiras.includes(id);
      let madeireiras;
      if (isRemove) {
        madeireiras = state.bookmarkList.madeireiras;
        madeireiras.pop(id);
      } else {
        madeireiras = state.bookmarkList.madeireiras.concat(id);
      }
      body.madeireiras = madeireiras;
      setState({
        ...state,
        bookmarkList: {
          ...state.bookmarkList,
          madeireiras,
        },
        bookmarks: isRemove
          ? state.bookmarks.filter((bk) => bk._id !== id)
          : [...state.bookmarks, state.company],
      });
    }
    if (name === "bookmarks-otimizador") {
      let isRemove = state.bookmarkList.otimizadores.includes(id);
      let otimizadores;
      if (isRemove) {
        otimizadores = state.bookmarkList.otimizadores;
        otimizadores.pop(id);
      } else {
        otimizadores = state.bookmarkList.otimizadores.concat(id);
      }
      body.otimizadores = otimizadores;
      setState({
        ...state,
        bookmarkList: {
          ...state.bookmarkList,
          otimizadores,
        },
        bookmarks: isRemove
          ? state.bookmarks.filter((bk) => bk._id !== id)
          : [...state.bookmarks, state.selectedOptimizator],
      });
    }
    let message = "";
    let appearance = "info";
    if (e.target.firstChild.textContent === "🗑️") {
      e.target.firstChild.textContent = "❤️";
      message = t("was removed to Bookmarks!");
      appearance = "warning";
    } else {
      e.target.firstChild.textContent = "🗑️";
      message = t("was added to Bookmarks!");
    }
    addToast(message, {
      appearance,
      autoDismiss: true,
      timeout: 2000,
    });
    setTimeout(() => {
      test(body);
    }, 11000);
  }

  function test(body) {
    requests({
      path: CONSTANTS.fetchBookmarks,
      options: {
        method: "post",
        body,
      },
    })
      .then((res) => {
        if (res && res.error) {
          addToast(res.error, {
            appearance: "error",
            autoDismiss: true,
            timeout: 2000,
          });
        } else {
          addToast("Favoritos atualizados em sua conta com sucesso!", {
            appearance: "info",
            autoDismiss: true,
            timeout: 2000,
          });
        }
      })
      .catch((e) => {
        console.error(e);
        addToast("Fala crítica ao contactar o servidor.", {
          appearance: "info",
          autoDismiss: true,
          timeout: 2000,
        });
      });
  }

  function handleDrop(files) {
    if (files) {
      setState({
        ...state,
        droppedFile: files[0],
      });
    }
  }

  function handleSelectOptimizator(value) {
    let selectedOptimizator = filter(state.optimizators, "name", value, true);
    if (value && selectedOptimizator) {
      let isBookmarked = state.bookmarks.find(
        (bk) => bk._id === selectedOptimizator._id,
      );
      setState({
        ...state,
        selectedOptimizator: {
          ...selectedOptimizator,
          isBookmarked: !!isBookmarked,
        },
        modelSheet: selectedOptimizator.modelSheet,
        hideOptimizators: false,
      });
    }
  }

  function handleSelectCity(selectedCity) {
    let partial = selectedCity ? selectedCity.split("-")[0] : "";
    let filteredWoods = filter(state.woods, "city", partial, false);
    if (partial && filteredWoods) {
      setState({
        ...state,
        selectedCity: partial,
        company: {},
        filteredWoods,
        selectedWood: "",
        hideCompany: true,
      });
    }
  }

  function handleSelectWoods(selectedWood) {
    let cp = filter(state.woods, "name", selectedWood, true);
    if (selectedWood && cp) {
      let isBookmarked = state.bookmarks.find((bk) => bk._id === cp._id);
      let fullAddress = `${cp.street}, ${cp.number} ${cp.neighborhood} - ${cp.city} (${cp.state})`;
      setState({
        ...state,
        selectedWood,
        isBlockConvertButton: false,
        company: cp
          ? {
              ...cp,
              fullAddress,
              isBookmarked: !!isBookmarked,
            }
          : {},
        hideCompany: false,
        modelSheet: cp.modelSheet,
        selectedOptimizator: "",
      });
    }
  }

  function handleRadios(e) {
    document.getElementById("options_form").reset();
    let id = e.target.id;
    let rad = {};
    for (var k in state.radios) {
      rad[k] = false;
    }
    rad[id] = true;
    if (id === "optimizator") {
      setState({
        ...state,
        radios: rad,
        hideCompany: true,
        modelSheet: null,
        selectedCity: "",
        selectedState: "",
        selectedWood: "",
        resetWoods: true,
        resetStates: true,
        isBlockConvertButton: true,
      });
    } else {
      setState({
        ...state,
        radios: rad,
        hideCompany: true,
        hideOptimizators: true,
        modelSheet: null,
        selectedCity: "",
        selectedState: "",
        selectedWood: "",
        resetOptimizators: true,
        resetStates: true,
        resetWoods: true,
        selectedOptimizator: "",
        isBlockConvertButton: false,
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (state.droppedFile) {
      popUp.open(
        "Ao realizar uma conversão, você utiliza 1 crédito. Deseja continuar?",
        [
          {
            text: "Sim",
            handleClick: () => {
              spinner.start("Processando...");
              let user = JSON.parse(localStorage.getItem("user"));
              let formdata = new FormData();
              formdata.append("file", state.droppedFile);
              formdata.set("id", user._id);
              formdata.set(
                "modelSheet",
                JSON.stringify(state.modelSheet) || "default",
              );
              formdata.set(
                "type",
                state.selectedOptimizator
                  ? "otimizador"
                  : state.selectedWood
                    ? "madeireira"
                    : "default",
              );
              formdata.set(
                "typeId",
                state.selectedOptimizator
                  ? state.selectedOptimizator._id
                  : state.selectedWood
                    ? filter(state.woods, "name", state.selectedWood, true)._id
                    : "",
              );
              fetch(`${CONSTANTS.qaHost}${CONSTANTS.convert}`, {
                method: "POST",
                headers: {
                  authorization: "Bearer " + token.replace(/"/gm, ""),
                },
                body: formdata,
              })
                .then(function (resp) {
                  if (resp.status == 403) {
                    popUp.open(
                      "Créditos insuficientes para concluir a conversão! Realize a compra de creditos ou aguarde a próximo mes!",
                      [
                        {
                          text: "OK",
                          handleClick: () => {
                            window.location = "/app/creditos";
                          },
                        },
                      ],
                    );
                    return "";
                  }
                  if (!resp.ok) {
                    spinner.finish();
                    popUp.open(t("register completion"), [
                      {
                        text: "OK",
                        handleClick: () => {
                          window.location = "/app/profile";
                        },
                      },
                    ]);
                    return "";
                  } else {
                    spinner.finish("Conversão realizada com sucesso!");
                    return resp.blob();
                  }
                })
                .then(function (blob) {
                  let newKey = Math.floor(Math.random() * 100);
                  if (blob)
                    saveAs(
                      blob,
                      `${state.droppedFile.name.split(".")[0]}.xlsx`,
                    );
                  window.setTimeout(() => {
                    props.stateChanger(newKey);
                  }, 2000);
                })
                .catch((e) => spinner.finish(e));
            },
          },
          {
            text: "Não",
          },
        ],
      );
    } else {
      popUp.open("Por favor selecione ou arraste um arquivo para a conversão.");
    }
  }

  async function fetchData() {
    try {
      spinner.start();
      let woods = await fetchWoods();
      const { cities } = filterWoodsData(woods);
      let optimizators = await fetchOptimizators();
      if (woods && optimizators) {
        let bookmarks = await fetchBookmarks();
        let bk = [];
        if (bookmarks && (bookmarks.otimizadores || bookmarks.madeireiras)) {
          bookmarks.otimizadores.forEach((opt) =>
            bk.push(filter(optimizators, "_id", opt, true)),
          );
          bookmarks.madeireiras.forEach((md) =>
            bk.push(filter(woods, "_id", md, true)),
          );
        }
        spinner.finish();
        setState({
          ...state,
          woods,
          optimizators,
          cities,
          bookmarks: bk || [],
          bookmarkList: bookmarks || state.bookmarkList,
        });
      } else {
        spinner.finish(
          "Erro ao buscar madeireiras e otimizadores, Aguarde alguns minutos e tente novamente.",
          2800,
        );
      }
    } catch (error) {
      history.push("/");
      spinner.finish(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <section id="conversor" className="container">
        <aside>
          <form
            id="options_form"
            className="d-flex flex-column ph-1 w-100 justify-content-center mt-5"
            action="submit"
            onSubmit={handleSubmit}
          >
            <input type="hidden" value="prayer" />
            <div className="d-flex flex-column justify-content-end p-0">
              <SelectSearch
                handleChange={handleSelectedBookmark}
                handleSelected={handleSelectedBookmark}
                id="bookmarks"
                options={state.bookmarks.map((bk) => bk.name)}
                name="bookmarks"
                placeholder={t("Bookmars search")}
              />
              <div className="d-flex flex-row w-100 h-10 align-items-center pv-2">
                <input
                  onChange={handleRadios}
                  checked={state.radios.wood}
                  type="radio"
                  id="wood"
                  name="madeireira"
                />
                <label>{t("logging")}</label>
              </div>
              <SelectSearch
                hidden={!state.radios.wood}
                handleChange={handleSelectCity}
                handleSelected={handleSelectCity}
                id="cs"
                options={state.cities}
                reset={state.resetStates}
                name="cs"
                placeholder={t("city search")}
                title={t("Search for city or state.")}
              />
              <SelectSearch
                disabled={!state.selectedCity}
                hidden={!state.radios.wood}
                reset={state.resetWoods}
                id="woodsSelector"
                options={state.filteredWoods.map((wood) => wood.name)}
                name="woodsSelector"
                handleChange={handleSelectWoods}
                handleSelected={handleSelectWoods}
                placeholder={t("woods placeholder")}
                title={t("First, search for city or state above")}
              />
              <a
                className="btn-transparent link w-100 text-bold text-center text-primary"
                href="/app/contact?logging=true"
                hidden={!state.radios.wood}
              >
                {t("logging recommend")}
              </a>
              <Card
                image={madeireira}
                hidden={state.hideCompany}
                title={state.company.name || ""}
                actions={
                  <button
                    className="link btn-transparent"
                    title={t("bookmark")}
                    id={state.company._id}
                    name="bookmarks-madeireira"
                    onClick={handleAddBookmark}
                  >
                    <h4>{state.company.isBookmarked ? "🗑️" : "❤️"}</h4>
                  </button>
                }
                children={[
                  <strong key={1}>{state.company.phone || ""}</strong>,
                  <strong key={2}>
                    <small>{state.company.email || ""}</small>
                  </strong>,
                  <strong key={3}>
                    <small>{state.company.fullAddress || ""}</small>
                  </strong>,
                ]}
              />
              <div className="d-flex flex-row w-100 h-10 align-items-center pv-2">
                <input
                  onChange={handleRadios}
                  checked={state.radios.optimizator}
                  type="radio"
                  id="optimizator"
                  name="otimizador"
                />
                <label>{t("optimizator")}</label>
              </div>
              <SelectSearch
                reset={state.resetOptimizators}
                hidden={!state.radios.optimizator}
                options={state.optimizators.map(
                  (optimizator) => optimizator.name,
                )}
                name="optimizatorsSelect"
                handleChange={handleSelectOptimizator}
                handleSelected={handleSelectOptimizator}
                placeholder={t("optimizator placeholder")}
                text={t("Search for cut Optizators.")}
              />
              <Card
                image={otimizador}
                hidden={state.hideOptimizators}
                title={state.selectedOptimizator.name}
                actions={
                  <button
                    className="link btn-transparent"
                    title={t("bookmark")}
                    id={state.selectedOptimizator._id}
                    name="bookmarks-otimizador"
                    onClick={handleAddBookmark}
                  >
                    <h4>
                      {state.selectedOptimizator.isBookmarked ? "🗑️" : "❤️"}
                    </h4>
                  </button>
                }
              />
            </div>
            <div className="d-flex flex-row w-100 h-10 align-items-center pv-2">
              <input
                onChange={handleRadios}
                checked={state.radios.sheet}
                type="radio"
                id="sheet"
                name="planilha"
              />
              <label>{t("Full Sheet")}</label>
            </div>
            <button className="btn btn-rounded text-dark text-bold btn-primary mv-1 converter-button">
              {t("convert")}
            </button>
          </form>
        </aside>
        <content className="converter-content">
          <DragNDrop
            onDrop={handleDrop}
            switchText={t("Switch")}
            selectText={t("Select File")}
            tip={t("or drag to dashed area.")}
          />
        </content>
      </section>
    </React.Fragment>
  );
}
