import React from "react";

import xmlico from "../../assets/xml-icon.png";
import optico from "../../assets/opt-icon.png";
import dwico from "../../assets/dw-file-icon.png";

import "./styles.css";

export default function SectionHeader() {
  return (
    <section id="header">
      <div className="pt-5 text-left d-flex flex-column justify-conent-center">
        <h2 className="text-white">
          <strong>Webcut.</strong>
        </h2>
        <h5 className="text-white">
          Converta seus arquivos do <em>Promob</em> em planilhas de forma rápida
          e simples.
        </h5>
      </div>
      <div className="d-flex w-100">
        <a
          href="#contato"
          className="text-white btn btn-outline border-light btn-rounded"
        >
          Contato
        </a>
        <a
          href="#register"
          className="btn border-primary bg-primary text-dark btn-rounded ml-2"
        >
          <strong>Cadastre-se</strong>
        </a>
      </div>
      <div className="step-images mt-3 d-flex justify-content-between">
        <img src={xmlico} alt="xml-icon" className="h-100" />
        <img src={optico} alt="option-icon" className="h1080" />
        <img src={dwico} alt="download-file-icon" className="h-100" />
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex bg-primary btn-rounded">
          <div className="p-1 bg-white btn-rounded"></div>
        </div>
        <div className="line w-100 bg-primary"></div>
        <div className="d-flex bg-primary btn-rounded">
          <div className="p-1 bg-white btn-rounded"></div>
        </div>
        <div className="line w-100 bg-primary"></div>
        <div className="d-flex bg-primary btn-rounded">
          <div className="p-1 btn-rounded"></div>
        </div>
      </div>
      <div className="mt-1 d-flex justify-content-between">
        <h6 className="w-30 text-light">
          <strong>Selecione o XML.</strong>
        </h6>
        <h6 className="w-30 text-light text-center">
          <strong>Ajuste as opções</strong>
        </h6>
        <h6 className="w-30 text-light text-right">
          <strong>Converta</strong>
        </h6>
      </div>
    </section>
  );
}
