export default [
    {
        label: "#",
        field: "order",
        width: "3%"
    },
    {
        label: "QTDE.",
        field: "quant",
        width: "3%"
    },
    {
        label: "Compr.",
        field: "comp",
        width: "3%"
    },
    {
        label: "Larg.",
        field: "larg",
        width: "5%"
    },
    {
        label: "Esp.",
        field: "esp",
        alt: "Espessura da chapa utilizada.",
        minWidth: "80px"
    },
    {
        label: "Material",
        field: "material",
        alt: "Material da chapa ex.: (MDF)"
    },
    {
        label: "Chapa",
        field: "chapa",
        minWidth: "250px"
    },
    {
        label: "Peça",
        field: "funcao",
        alt: "Uso da peça, Ex: Base inferior, lateral, tampo, etc..",
        minWidth: "90px"
    },
    {
        label: "Obs.",
        field: "obs",
        textAlign: "center",
        alt: "Função para peça, Ex: Furo de dobradiça, Rasgo, engrossar, etc."
    },
    {
        label: "Veio",
        field: "rotaciona",
        textAlign: "center",
        width: "5%",
        alt: "Pode rotacionar o sentido do veio da chapa?"
    },
    {
        label: "Fita",
        field: "fita",
        minWidth: "250px"
    },
    {
        label: "Filetação",
        field: "filetacao",
        textAlign: "center",
        width: "5%"
    },
    {
        label: "Ações",
        field: "actions",
        textAlign: "center",
        width: "5%"
    },
    {
        label: "Sel.",
        field: "selected",
        width: "50px",
        alt: "selecionar todos"
    }
];
