import React, { Component } from "react";
import PropTypes from "prop-types";

import "./styles.css";

class DragNDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filename: "",
    };
  }
  onDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.remove("is-dragover");
    let files, filename;
    if (e.target.name === "files[]") {
      document.querySelector("#filename").classList.remove("text-danger");
      filename = e.target.files[0] ? e.target.files[0].name : "";
      files = e.target.files;
    } else {
      filename = e.dataTransfer.files[0].name;
      files = e.dataTransfer.files;
    }
    if (filename && filename.split(".")[1] === "xml") {
      this.setState({
        filename,
      });
      if (
        this.props.onDrop &&
        typeof this.props.onDrop === "function" &&
        files[0]
      )
        this.props.onDrop(files);
    } else {
      document.querySelector("#filename").classList.add("text-danger");
      this.setState({
        filename: "Formato de arquivo Inválido.",
      });
    }
  };

  render() {
    return (
      <div
        className="box has-advanced-upload"
        onDragEnter={(e) => {
          this.onDrag(e);
          e.target.classList.add("is-dragover");
        }}
        onDragOver={(e) => {
          this.onDrag(e);
          e.target.classList.add("is-dragover");
        }}
        onDragEnd={(e) => {
          this.onDrag(e);
          e.target.classList.remove("is-dragover");
        }}
        onDragLeave={(e) => {
          this.onDrag(e);
          e.target.classList.remove("is-dragover");
        }}
        onDragStart={this.onDrag}
        onDrop={this.onDrop}
      >
        {this.props.children}
        <div className="animation-box">
          <div className="file">
            <div className={this.state.filename ? "recipe-uploaded" : "recipe"}>
              <span className={this.state.filename ? "uploaded" : ""}>
                {this.state.filename ? "🗸" : "⤓"}
              </span>
            </div>
          </div>
        </div>
        <div className="box__input">
          <input
            onChange={this.onDrop}
            className="box__input_file"
            type="file"
            name="files[]"
            id="file"
            data-multiple-caption="{count} files selected"
            multiple
            accept=".xml"
          />
          <label
            className="text-bold btn btn-primary text-dark btn-rounded p-1"
            htmlFor="file"
            name="file"
          >
            {this.state.filename
              ? this.props.switchText || "Trocar"
              : this.props.selectText || "Selecione o arquivo"}
          </label>
          <span id="filename" className="text-dark p-2 mb-4">
            {this.state.filename ||
              this.props.tip ||
              "ou arraste para a área tracejada."}
          </span>
        </div>
      </div>
    );
  }
}

DragNDrop.propTypes = {
  tip: PropTypes.string,
  switchText: PropTypes.string,
  selectText: PropTypes.string,
  onDrop: PropTypes.func,
};
export default DragNDrop;
