export default class Spinner {
    constructor() {
        let div = document.createElement("div");
        let spinner = document.createElement("div");
        this.p = document.createElement("p");

        div.classList.add("lock-screen-spinner");
        div.align = "center";
        spinner.classList.add("lds-roller");
        this.p.classList.add("text-white", "h5", "text-bold");
        this.p.id = "spinner__message";

        for (var i = 0; i < 8; i++) {
            spinner.appendChild(document.createElement("div"));
        }
        div.appendChild(spinner);
        div.lastChild.after(this.p);
        this.loader = div;
        this.timeout = 400;
    }
    setTimeout(time) {
        this.timeout = time || 400;
    }
    start(message) {
        this.p.textContent = message || "";
        document.body.lastChild.after(this.loader);
    }
    finish(callBackMessage, timeout) {
        if (callBackMessage) {
            this.p.textContent = callBackMessage;
        }
        setTimeout(() => {
            document.body.removeChild(this.loader);
        }, timeout || this.timeout);
    }
}
