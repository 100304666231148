import React from "react";
import "./styles.css";

export default function MaterialInput(props) {
  const focus = (e) => {
    e.target.parentElement
      .querySelector("label")
      .classList.add("focused-label");
  };

  const blur = (e) => {
    let value = e.target.value;
    if (value) {
      e.target.parentElement
        .querySelector("label")
        .classList.add("valid-input");
    }
    e.target.parentElement
      .querySelector("label")
      .classList.remove("focused-label");
  };

  return (
    <div hidden={props.hidden || false} className="input-wrapper">
      <textarea
        cols={props.cols || "50"}
        rows={props.rows || "4"}
        maxLength={props.maxLength || 1024}
        className="textarea"
        type={"text"}
        name={props.name || ""}
        value={props.value || ""}
        onChange={props.onChange || function () {}}
        onFocus={focus}
        onBlur={blur}
        disabled={props.disabled || false}
      />
      <label className="label">{props.placeholder}</label>
      <span className="bar"></span>
    </div>
  );
}
