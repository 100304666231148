import styled from "styled-components";

export const Input = styled.input`
    border: transparent;
    border-bottom: solid thin var(--light);
    padding: 0.4rem;
    background: transparent;
    color: var(--light);
    flex: 1 0 250px;
    width: 100%;
    max-height: 45px;
    outline: none;
    font-size: 12pt;
    margin-bottom: 1rem;

    &:focus {
        background-color: rgba(255, 255, 255, 0.08);
        border-bottom-color: var(--primary);
    }
`;

export const CutplanWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3.5rem;
    justify-content: center;
    color: var(--light);
    background-color: transparent;
    position: relative;
`;

export const CutplanHeaderWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1700px;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    padding-bottom: 1.5rem;
    position: relative;
`;

export const CutplanTableWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 1rem;
    background: white;
    min-height: 450px;
    color: var(--darker);
    position: relative;
`;

export const RoundedButton = styled.button`
    outline: ${(props) => props.outline || "none"};
    background: transparent;
    color: ${(props) => props.color || "black"};
    border: solid ${(props) => props.borderWidth || "1px"};
    border-color: ${(props) => props.borderColor || "black"};
    ${(props) => (props.borderBottom ? `border-bottom-color: ${props.selectedBorderColor}` : "")};
    ${(props) => (props.borderTop ? `border-top-color: ${props.selectedBorderColor}` : "")};
    ${(props) => (props.borderLeft ? `border-left-color: ${props.selectedBorderColor}` : "")};
    ${(props) => (props.borderRight ? `border-right-color: ${props.selectedBorderColor}` : "")};
    font-weight: ${(props) => props.fontWeight || "bold"};
    border-radius: ${(props) => props.borderRadius || "60rem"};
    padding: ${(props) => props.padding || "0"};
    margin: ${(props) => props.margin || ".25rem"};
    width: ${(props) => props.width || "30px"};
    height: ${(props) => props.width || "30px"};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const InputTable = styled.input`
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    font-size: 9pt;
    color: black;
    padding-left: 5px;
    outline-color: black;
    padding-right: 5px;
    font-weight: bold;
    margin-bottom: 1px;
`;

export const FilletButton = styled.button`
    outline: ${(props) => props.outline || "none"};
    border: solid ${(props) => props.borderWidth || "1px"};
    background: transparent;
    color: ${(props) => props.color || "black"};
    border-color: ${(props) => props.color || "transparent"};
    font-weight: ${(props) => props.fontWeight || "bold"};
    border-radius: ${(props) => props.borderRadius || "60rem"};
    padding: ${(props) => props.padding || "0"};
    margin: ${(props) => props.margin || ".25rem"};
    width: ${(props) => props.width || "30px"};
    height: ${(props) => props.width || "30px"};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;
