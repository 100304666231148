import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import querystring from "query-string";

import StoreContext from "../../components/Store/Context";
import Navbar from "../../components/Navbar";
import MaterialInput from "../../components/MaterialInput";
import Footer from "../../components/Footer";
import SectionHeader from "../../components/SectionHeader";
import SectionRegister from "../../components/SectionRegister";
import SectionContact from "../../components/SectionContact";

import { CONSTANTS } from "../../config";
import { popUp, requests } from "../../utils";
import Spinner from "../../utils/Spinner";

import "./styles.css";

const spinner = new Spinner();

function initialState() {
    return { user: "", password: "", rememberMe: false };
}

export default function Home(props) {
    const [values, setValues] = useState(initialState);
    const [isPassHidden, setIsPassHidden] = useState(false);
    const [isLoginHidden, setIsLoginHidden] = useState(true);
    const [error, setError] = useState(null);
    const { setToken } = useContext(StoreContext);
    const history = useHistory();

    function login({ user, password }) {
        if (user && password) {
            spinner.start();
            return requests({
                path: CONSTANTS.loginOperation,
                options: {
                    method: "post",
                    body: {
                        email: user,
                        password
                    }
                }
            }).catch((e) => ({ error: e }));
        }
        return { error: "Usuário ou senha inválido" };
    }

    function forgotPassword(user) {
        if (user) {
            spinner.start();
            requests({
                path: CONSTANTS.forgotPassword,
                options: {
                    method: "post",
                    body: {
                        email: user
                    }
                }
            })
                .then((res) => {
                    spinner.finish();
                    if (res.error) {
                        if (res.status == "400") popUp.open(res.error);
                        else
                            popUp.open(
                                "Erro de comunicação com o servidor, por favor tente novamente mais tarde"
                            );
                    } else {
                        popUp.open(
                            "Email enviado com sucesso! Por favor siga as instruções encaminhadas ao email."
                        );
                    }
                })
                .catch((e) => {
                    spinner.finish(
                        "Erro ao tentar recuperar a senha, verifique seu email e tente novamente."
                    );
                });
        }
    }

    function onChange(event) {
        const { value, name } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    async function onSubmit(event) {
        event.preventDefault();
        if (isPassHidden) {
            forgotPassword(values.user);
        } else {
            const { user, password } = values;
            const response = await login({ user, password });

            if (response.error) {
                spinner.finish();
                let status = parseInt(response.status);
                if (status < 500) popUp.open(response.error);
                else popUp.open("Erro ao contactar o servidor, tente novamente mais tarde.");
            }

            if (response.token) {
                spinner.finish();
                setToken(response.token);
                localStorage.setItem("user", JSON.stringify(response.user));
                return history.push("/app");
            }

            setError(error);
            setValues(initialState);
        }
    }

    function validateToken() {
        if (localStorage.getItem("token")) {
            spinner.start();
            requests({
                path: CONSTANTS.fetchOptimizator,
                options: {
                    headers: {
                        authorization: localStorage.token
                    }
                }
            })
                .then((resp) => {
                    spinner.finish();
                    if (resp.error) {
                        let status = parseInt(resp.status);
                        if (status < 500) popUp.open(resp.error);
                        else
                            popUp.open(
                                "Por algum erro de comunicação não conseguimos fazer seu login automático, sentimos muito por isso. \nPor favor, entre novamente com seu usuário e senha",
                                [
                                    {
                                        text: "OK",
                                        handleClick: () => {
                                            localStorage.clear();
                                            window.location = "/#head";
                                        }
                                    }
                                ]
                            );
                    } else {
                        history.push("/app");
                    }
                })
                .catch((e) => console.error(e));
        }
    }

    useEffect(() => {
        let params = querystring.parse(props.location.search);
        if (Object.keys(params).length > 0) {
            spinner.start();
            requests({
                path: CONSTANTS.activateUser,
                options: {
                    method: "post",
                    body: {
                        iv: params.i
                    }
                }
            })
                .then((resp) => {
                    spinner.finish();
                    if (resp && resp.error) {
                        let status = parseInt(resp.status);
                        if (status < 500) {
                            popUp.open(resp.error);
                        } else {
                            popUp.open(
                                "erro na comunicação com o servidor, tentar novamente mais tarde."
                            );
                        }
                    } else {
                        popUp.open("usuário ativado com sucesso! Prossiga com o login.");
                        setIsLoginHidden(false);
                    }
                })
                .catch(() => {
                    spinner.finish();
                    popUp.open(
                        "erro na ativação do usuário, volte ao email e clique em 'Ativar Usuário' para tentar novamente."
                    );
                    setIsLoginHidden(false);
                    window.location.search = "";
                });
        }
        if (error) {
            spinner.finish(error);
        }
        validateToken();
    }, [error, props.location.search]);

    return (
        <div id='App'>
            <Navbar
                brandName='Webcut'
                theme='light'
                links={[
                    {
                        btnClass:
                            "btn btn-primary border-primary text-dark text-bold btn-rounded m-0",
                        name: "Entrar",
                        to: "#head",
                        onClick: () => setIsLoginHidden(!isLoginHidden)
                    }
                ]}
                children={
                    <React.Fragment>
                        <form onSubmit={onSubmit} hidden={isLoginHidden} className='login-box'>
                            <MaterialInput
                                name='user'
                                placeholder='email'
                                type='email'
                                value={values.user}
                                onChange={onChange}
                            />
                            <MaterialInput
                                hidden={isPassHidden}
                                name='password'
                                placeholder='password'
                                type='password'
                                value={values.password}
                                onChange={onChange}
                            />
                            <button
                                type='submit'
                                className='btn btn-primary text-bold text-dark btn-rounded mt-2'>
                                {isPassHidden ? "Enviar" : "Entrar"}
                            </button>
                            <div
                                hidden={isPassHidden}
                                className='mt-1 text-light text-bold d-flex w-100 align-items-center'>
                                <input
                                    className='mr-1'
                                    type='checkbox'
                                    onChange={() =>
                                        setValues({
                                            ...values,
                                            rememberMe: !values.rememberMe
                                        })
                                    }
                                />
                                <label>Lembrar usuário?</label>
                            </div>
                            <div className='mt-1 link'>
                                <a
                                    onClick={() => setIsPassHidden(!isPassHidden)}
                                    className='dark text-dark text-bold'>
                                    {isPassHidden ? "Voltar ao login" : "Esqueci a senha"}
                                </a>
                            </div>
                        </form>
                    </React.Fragment>
                }
            />
            <SectionHeader />
            <SectionRegister />
            <SectionContact />
            <Footer title='Webcut' description=' todos os direitos reservados.' />
        </div>
    );
}
