import React from "react";
import "./styles.css";

export default function Footer(props) {
  return (
    <footer>
      <p>
        Copyright ©<em>{props.title}</em> {props.description}.
      </p>
    </footer>
  );
}
