import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import Spinner from "../../utils/Spinner";
import { requestList, removeSheet } from "./functions";
import { Input } from "./styles";
import { useToasts } from "react-toast-notifications";
import SimpleTable from "../../components/SimpleTable";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { popUp } from "../../utils";
import SideMenu from "../../components/SideMenu";
const ClickOutHandler = require("react-onclickout");

export default function ListPage(props) {
    const spinner = new Spinner();
    const [filter, setFilter] = useState("");
    const [debounced, setDebounced] = useState("");
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [showSideMenu, setShowSideMenu] = useState(false);
    const [sheet, setSheet] = useState("0");
    const { addToast } = useToasts();
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        let newData = filterData(debounced);
        if (newData.length > 0) setFilteredData(newData);
        else setFilteredData(data);
    }, [debounced]);

    useEffect(() => {
        fetchList();
    }, []);

    const fetchList = async () => {
        spinner.start("Buscando Lista...");
        let result = await requestList();
        spinner.finish();
        if (result && result.error) {
            addToast("Erro ao buscar clientes.", {
                appearance: "error"
            });
        } else {
            setData(result);
            setFilteredData(result);
        }
    };

    const filterData = (value) => {
        return data.filter((item) => {
            let clientName = item.clientName ? item.clientName.toLowerCase() : "";
            return clientName.includes(value.toLowerCase());
        });
    };

    const removeRow = (event, id) => {
        event.stopPropagation();
        event.preventDefault();
        popUp.open("Você realmente deseja remover o cliente?", [
            {
                text: "SIM",
                handleClick: function () {
                    let newData = data.filter((item) => item._id !== id);
                    setData(newData);
                    setFilteredData(newData);
                    removeSheet(id).then((res) => {
                        if (res && res.error) {
                            addToast(res.error, {
                                appearance: "error"
                            });
                        } else {
                            addToast("Cliente removido com sucesso!", {
                                appearance: "success"
                            });
                        }
                    });
                }
            },
            {
                text: "NÃO"
            }
        ]);
    };

    const search = (e) => {
        const value = e.target.value;
        setFilter(value);
        clearTimeout(timeout);
        //foi usado var para aproveitar o hoisting...
        var timeout = setTimeout(() => {
            setDebounced(value);
        }, 1000);
    };

    const editRow = (id) => {
        history.push("/app/service/" + id);
    };
    const onClickOut = (e) => {
        let className = String(e.target.className);
        if (showSideMenu && !className.includes("xls-button")) {
            setShowSideMenu(false);
        }
    };

    const dataAtualFormatada = (data) => {
        let dia = data.getDate().toString();
        let diaF = dia.length == 1 ? "0" + dia : dia;
        let mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
        let mesF = mes.length == 1 ? "0" + mes : mes;
        let anoF = data.getFullYear();
        return diaF + "/" + mesF + "/" + anoF;
    };
    return (
        <>
            <section
                className='d-flex w-100 justify-content-center align-items-center contact-container pv-3'
                id='profile'>
                <ClickOutHandler onClickOut={onClickOut}>
                    <SideMenu show={showSideMenu} sheet={sheet} stateChanger={props.stateChanger}>
                        <button
                            style={{
                                width: "30px",
                                height: "30px",
                                backgroundColor: "transparent",
                                boxShadow: "none"
                            }}
                            className='p-1 btn'
                            onClick={function () {
                                setShowSideMenu(!showSideMenu);
                            }}>
                            <i
                                className='fa fa-chevron-left'
                                style={{ color: "rgba(255,255,255,.6)" }}
                                aria-hidden='true'></i>
                        </button>
                    </SideMenu>
                </ClickOutHandler>
                <div className='contact-form ph-1 mv-5 d-flex align-items-center flex-wrap justify-content-center'>
                    <div className='flex-wrap d-flex w-100 justify-content-between align-items-center pr-0 pl-0 pb-3'>
                        <div
                            className='d-flex w-100 flex-row pl-1 pr-1'
                            style={{ flex: "1 0 250px" }}>
                            <Input
                                value={filter}
                                onChange={search}
                                placeholder='&#128269; Pequisar por cliente'
                                type='search'
                            />
                        </div>
                        <div
                            className='flex-wrap d-flex justify-content-end'
                            style={{ whiteSpace: "nowrap", flex: "500px 1 1" }}>
                            <div
                                style={{
                                    padding: ".2rem",
                                    flex: "1 0 150px"
                                }}>
                                <button
                                    className='w-100 btn btn-outline border-primary text-primary btn-rounded mb-1'
                                    onClick={() => (window.location = "/app/converter")}>
                                    Converter xml promob
                                </button>
                            </div>
                            <div
                                style={{
                                    padding: ".2rem",
                                    flex: "1 0 150px"
                                }}>
                                <button
                                    onClick={() => (window.location = "/app/service/0")}
                                    className='w-100 btn btn-outline border-primary text-primary btn-rounded mb-1'>
                                    <i className='fa fa-plus justify-content-center pr-1'></i>Criar
                                    lista de corte manual
                                </button>
                            </div>
                        </div>
                    </div>
                    <SimpleTable
                        bordered={true}
                        maxHeight='400px'
                        minHeight='300px'
                        columns={[
                            {
                                label: "Serviço",
                                field: "order",
                                width: "5%"
                            },
                            {
                                label: "Cliente",
                                field: "clientName"
                            },
                            {
                                label: "Data da Criação",
                                field: "created_at",
                                width: "15%"
                            },
                            {
                                label: "Ultima Atualização",
                                field: "updated_at",
                                width: "20%"
                            },
                            {
                                label: "Ações",
                                field: "action",
                                width: "11%"
                            }
                        ]}
                        rows={filteredData.map((item) => {
                            var newItem = { ...item };
                            newItem.handleClick = function () {
                                editRow(newItem._id);
                            };
                            newItem["created_at"] = dataAtualFormatada(
                                new Date(item["created_at"])
                            );
                            newItem["updated_at"] = dataAtualFormatada(new Date(item["updatedAt"]));
                            newItem.action = (
                                <div className='d-flex flex-row w-100 justify-content-between align-items-center m-0 '>
                                    <button
                                        style={{ width: "30px", height: "30px" }}
                                        className='p-0 btn btn-outline border-light text-light btn-rounded xls-button'
                                        data-tip='Gerar Planilha'
                                        onClick={function (e) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setShowSideMenu(!showSideMenu);
                                            setSheet(item);
                                        }}>
                                        {" "}
                                        <i
                                            className='fa fa-file-excel-o xls-button'
                                            style={{ color: "rgba(255,255,255,.6)" }}
                                            aria-hidden='true'></i>
                                    </button>
                                    <ReactTooltip />
                                    <button
                                        style={{ width: "30px", height: "30px" }}
                                        className='p-0 btn btn-outline border-light text-light btn-rounded'
                                        data-tip='Remover Item'
                                        onClick={function (e) {
                                            removeRow(e, newItem._id);
                                        }}>
                                        {" "}
                                        <i
                                            className='fa fa-trash'
                                            style={{ color: "rgba(255,255,255,.6)" }}
                                            aria-hidden='true'></i>
                                    </button>
                                    <ReactTooltip />
                                </div>
                            );
                            return newItem;
                        })}
                    />
                </div>
            </section>
        </>
    );
}
