import React, { useState, useEffect } from "react";
import {
  requests,
  popUp,
  fixAutofillInputs,
  dateMask,
  phoneMask,
  cepMask,
} from "../../utils";
import Spinner from "../../utils/Spinner";
import { CONSTANTS } from "../../config";
import { getIdFromPath } from "../Cutplan/Business/functions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MUIDataTable from "mui-datatables";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Chip from "@mui/material/Chip";

const clickLink = (data) => {
  window.location = `/app/purchase/${data.rowData[0]}`;
};
const columns = [
  {
    name: "txid",
    label: "ID",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "credito",
    label: "Crédito",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "valor",
    label: "Valor",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value) {
          return `R$ ${value.toLocaleString("pt-br", { minimumFractionDigits: 2 })}`;
        } else return "N/A";
      },
    },
  },
  {
    name: "created_at",
    label: "Data Criação",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "updatedAt",
    label: "Data Update",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value == "ATIVA" || value == "Aguardando_Pagamento") {
          return (
            <Chip
              label="Agurdando Pagamento"
              color="warning"
              variant="outlined"
            />
          );
        } else {
          return <Chip label={value} color="success" variant="outlined" />;
        }
      },
    },
  },
  {
    name: "click",
    label: "Link",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <RemoveRedEyeIcon
          style={{ cursor: "pointer" }}
          onClick={() => clickLink(tableMeta)}
        />
      ),
    },
  },
];

const options = {
  responsive: "standard",
  filter: false,
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
};

function Purchases(props) {
  const [userPurchase, setUserPurchase] = useState();

  useEffect(() => {
    function getPayment(id, intervall = null) {
      let spinner = new Spinner();
      spinner.start();
      requests({
        path: CONSTANTS.paymentsGet,
        params: id,
        options: {
          method: "GET",
        },
      })
        .then((res) => {
          spinner.finish();
          if (res && res.error) {
            if (res.status < 404) {
              popUp.open(res.error);
            } else {
              popUp.open("Erro na comunicação com o servidor.");
            }
          } else {
            setUserPurchase(res);
            if (res.status === "CONCLUIDA") {
              clearInterval(intervall);
            }
          }
        })
        .catch((e) => {
          spinner.finish();
          popUp.open(
            "Erro desconhecido!\n Por favor, tente novamente mais tarde.",
          );
        });
    }
    getPayment();
  }, []);
  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1, p: 4, pt: 15 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MUIDataTable
              title={"Minhas Compras"}
              data={userPurchase}
              columns={columns}
              options={options}
            />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Purchases;
