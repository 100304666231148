import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      convert: "Convert",
      "register completion":
        "Você precisa completar o seu cadastro para continuar!\nÉ rápidinho e você pode continuar utilizando a plataforma gratuitamente",
      "woods placeholder": "Search logging",
      "optimizator placeholder": "Search Cut Optimizator",
      "state search": "State search",
      "city search": "City Search",
      "logging recommend": "Recommend a logging",
      "Bookmars search": "Bookmars search",
      "was removed to Bookmarks!": "was removed to Bookmarks!",
      "was added to Bookmarks!": "was added to Bookmarks!",
      bookmark: "bookmarks",
      logging: "Logging",
      "First, search for city or state above":
        "First, search for city or state above",
      "Search for city or state.": "Search for city or state.",
      "or drag to dashed area.": "or drag to dashed area.",
      "Select File": "Select File",
      Switch: "Switch",
      "Full Sheet": "Full Sheet",
      "Search for cut Optizators.": "Search for cut Optizators.",
      optimizator: "Optimizator",
      "New Sheet": "New Sheet",
      delete: "Delete"
    },
  },
  ptBR: {
    translation: {
      convert: "Converter",
      "register completion":
        "Você precisa completar o seu cadastro para continuar!\nÉ rápidinho e você pode continuar utilizando a plataforma gratuitamente",
      "woods placeholder": "Buscar madeireira",
      "optimizator placeholder": "Buscar Otimizador",
      "state search": "Buscar estado",
      "city search": "Buscar Região",
      "logging recommend": "Indicar uma madeireira",
      "Bookmars search": "Buscar favoritos",
      "was removed to Bookmarks!": "Ok, removido dos favoritos!",
      "was added to Bookmarks!": "Adicionado aos favoritos!",
      bookmark: "Favoritar",
      logging: "Madeireira",
      "First, search for city or state above":
        "Pesquise primeiro por cidade/estado.",
      "Search for city or state.": "Buscar por cidade/estado",
      "or drag to dashed area.": "Ou arraste para a área tracejada.",
      "Select File": "Selecione",
      Switch: "Trocar",
      "Full Sheet": "Planilha completa",
      "Search for cut Optizators.": "Buscar otimizadores.",
      optimizator: "Otimizador",
      "New Sheet": "Nova Planilha",
      delete: "Deletar"
    },
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "ptBR",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
