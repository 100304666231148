import React, { Component } from "react";

import MaterialInput from "../../components/MaterialInput";

import { requests, popUp, fixAutofillInputs, dateMask, phoneMask, cepMask } from "../../utils";
import Spinner from "../../utils/Spinner";
import { CONSTANTS } from "../../config";
import {isValidCPF} from "../../utils/masks"

function maskCPF(e){
      return e
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}
export default class Profile extends Component {
    constructor() {
        super();
        this.state = {
            _id: "",
            cpf: "",
            email: "",
            phone: "",
            cep: "",
            name: "",
            surName: "",
            city: "",
            state: "",
            neighborhood: "",
            bornDate: "",
            gender: "",
            brand: "",
            occupation: "",
            howKnowsPlattform: "",
            haveWoods: false,
            thirdPartyFillet: false,
            thirdPartyCut: false,
            data: {
                brands: [],
                occupations: []
            }
        };
    }

    onChange = (e) => {
        let { value, name: field } = e.target;
        if (field === "phone") {
            value = phoneMask(value);
        }
        if (field === "bornDate") {
            value = dateMask(value);
        }
        if (field === "cep") {
            value = cepMask(value);
        }
        if (field == "cpf"){
            value = maskCPF(value);
        }
        this.setState({
            ...this.state,
            [field]: value
        });
    };

    getUser = () => {
        let user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            let spin = new Spinner();
            spin.start();
            requests({
                path: CONSTANTS.usersOperation,
                queryString: `?email=${user.email}`
            }).then((res) => {
                spin.finish();
                if (res && res.error) {
                    if (res.status < 404) {
                        popUp.open(res.error);
                    } else {
                        window.location = "/";
                        popUp.open("Erro na comunicação com o servidor.");
                    }
                } else {
                    const {
                        _id,
                        cpf,
                        email,
                        phone,
                        cep,
                        name,
                        surName,
                        city,
                        state,
                        neighborhood,
                        bornDate,
                        gender,
                        brand,
                        occupation,
                        howKnowsPlattform,
                        haveWoods,
                        thirdPartyFillet,
                        thirdPartyCut
                    } = Array.isArray(res) ? res[0] : res;
                    this.setState(
                        {
                            ...this.state,
                            _id,
                            cpf: cpf ? maskCPF(cpf) : "",
                            email,
                            phone: phone ? phoneMask(phone) : "",
                            cep: cep ? cepMask(cep) : "",
                            name,
                            surName,
                            city,
                            state,
                            neighborhood,
                            bornDate: bornDate ? dateMask(bornDate) : "",
                            gender,
                            brand,
                            occupation,
                            howKnowsPlattform,
                            haveWoods,
                            thirdPartyFillet,
                            thirdPartyCut
                        },
                        () => this.correctFields()
                    );
                }
            });
        } else {
            popUp.open("Erro ao buscar usuário!");
            localStorage.clear();
            window.location = "/";
        }
    };

    getOccupations = () => {
        let spinner = new Spinner();
        spinner.start();
        requests({
            path: CONSTANTS.fetchOccupations
        }).then((res) => {
            spinner.finish();
            if (res && res.error) {
                if (res.status < 404) {
                    popUp.open(res.error);
                } else {
                    window.location = "/";
                    popUp.open("Erro na comunicação com o servidor.");
                }
            } else {
                this.setState({
                    ...this.state,
                    data: { ...this.state.data, occupations: res }
                });
            }
        });
    };

    getFabricantes = () => {
        let spinner = new Spinner();
        spinner.start();
        requests({
            path: CONSTANTS.fetchFabricantes
        }).then((res) => {
            spinner.finish();
            if (res && res.error) {
                if (res.status < 404) {
                    popUp.open(res.error);
                } else {
                    window.location = "/";
                    popUp.open("Erro na comunicação com o servidor.");
                }
            } else {
                this.setState({
                    ...this.state,
                    data: { ...this.state.data, brands: res }
                });
            }
        });
    };

    correctFields = () => {
        for (var k in this.state) {
            if (typeof this.state[k] !== "object") {
                if (this.state[k]) {
                    fixAutofillInputs(`[name=${k}]+label`);
                }
            }
        }
    };

    submit = (e) => {
        e.preventDefault();
        for (var k in this.state) {
            if (typeof this.state[k] !== "object") {
                if (
                    k !== "haveWoods" &&
                    k !== "thirdPartyCut" &&
                    k !== "thirdPartyFillet" &&
                    k !== "incompleteRegister" &&
                    k !== "blockedUser" &&
                    k !== "_id" &&
                    !this.state[k]
                ) {
                    popUp.open(`Por favor preencha todas as informações solicitadas.`);
                    return;
                }
            }
        }
        if (!isValidCPF(this.state.cpf)){
            popUp.open(`Por favor, digite um número de CPF valido.`);
            return;
        }
        let spinner = new Spinner();
        spinner.start();
        let body = { ...this.state };
        delete body.data;
        body.incompleteRegister = false;
        body.blockedUser = false;
        requests({
            path: CONSTANTS.usersOperation,
            options: {
                method: "put",
                body
            }
        })
            .then((res) => {
                spinner.finish();
                if (res && res.error) {
                    if (res.status < 404) {
                        popUp.open(res.error);
                    } else {
                        popUp.open("Erro na comunicação com o servidor.");
                    }
                } else {
                    popUp.open("Usuário atualizado com sucesso!", [
                        {
                            text: "OK",
                            handleClick: () => {
                                localStorage.setItem("user", JSON.stringify(body));
                                window.location = "/app";
                            }
                        }
                    ]);
                }
            })
            .catch((e) => {
                spinner.finish();
                popUp.open(
                    "Erro desconhecido!\n Por favor, verifique as informações digitadas/selecionadas e tente novamente."
                );
            });
    };

    componentDidMount() {
        this.getUser();
        this.getFabricantes();
        this.getOccupations();
    }

    render() {
        return (
            <React.Fragment>
                <section
                    className='d-flex w-100 justify-content-center align-items-center contact-container pv-3'
                    id='profile'>
                    <form
                        action='submit'
                        onSubmit={this.submit}
                        className='contact-form ph-1 mv-5 d-flex align-items-center flex-wrap justify-content-center'>
                        <MaterialInput
                            id='cpf'
                            name='cpf'
                            type='text'
                            placeholder='CPF *'
                            value={this.state.cpf}
                            onChange={this.onChange}
                        />
                        <MaterialInput
                            id='name'
                            name='name'
                            type='text'
                            placeholder='Nome'
                            value={this.state.name}
                            onChange={this.onChange}
                        />
                        <MaterialInput
                            id='surName'
                            name='surName'
                            type='text'
                            placeholder='Sobrenome'
                            value={this.state.surName}
                            onChange={this.onChange}
                        />
                        <MaterialInput
                            id='email'
                            name='email'
                            type='text'
                            placeholder='Email *'
                            value={this.state.email}
                            disabled={true}
                        />
                        <MaterialInput
                            id='phone'
                            name='phone'
                            type='text'
                            maxLength='14'
                            placeholder='Telefone *'
                            value={this.state.phone}
                            onChange={this.onChange}
                        />
                        <MaterialInput
                            id='cep'
                            name='cep'
                            type='text'
                            maxLength='9'
                            placeholder='Cep'
                            value={this.state.cep}
                            onChange={this.onChange}
                        />
                        <MaterialInput
                            id='state'
                            name='state'
                            type='text'
                            placeholder='Estado'
                            value={this.state.state}
                            onChange={this.onChange}
                        />
                        <MaterialInput
                            id='city'
                            name='city'
                            type='text'
                            placeholder='Cidade'
                            value={this.state.city}
                            onChange={this.onChange}
                        />
                        <MaterialInput
                            id='neighborhood'
                            name='neighborhood'
                            type='text'
                            placeholder='Bairro'
                            value={this.state.neighborhood}
                            onChange={this.onChange}
                        />
                        <MaterialInput
                            id='bornDate'
                            name='bornDate'
                            type='text'
                            placeholder='Data de nascimento *'
                            value={this.state.bornDate}
                            onChange={this.onChange}
                        />
                        <div className='select-wrapper mt-3'>
                            <select
                                value={this.state.gender || "default"}
                                onChange={this.onChange}
                                name='gender'
                                id='gender'>
                                <option value='default'>Gênero *</option>
                                <option value='Masculino'>Masculino</option>
                                <option value='Feminino'>Feminino</option>
                                <option value='Prefiro não iformar'>Prefiro não iformar</option>
                            </select>
                        </div>
                        <div className='select-wrapper mt-3'>
                            <select
                                onChange={this.onChange}
                                name='occupation'
                                id='occupation'
                                value={this.state.occupation || "default"}>
                                <option value='default'>Qual a sua profissão?</option>
                                {this.state.data.occupations.map((occupation, index) => {
                                    return (
                                        <option key={index} value={occupation}>
                                            {occupation}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className='select-wrapper mt-3'>
                            <select
                                onChange={this.onChange}
                                name='brand'
                                id='brand'
                                value={this.state.brand || "default"}>
                                <option value='default'>Qual Fabricante você mais utiliza?</option>
                                {this.state.data.brands.map((brand, index) => {
                                    return (
                                        <option key={index} value={brand}>
                                            {brand}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className='select-wrapper mt-3'>
                            <select
                                onChange={this.onChange}
                                name='howKnowsPlattform'
                                id='howKnowsPlattform'
                                value={this.state.howKnowsPlattform || "default"}>
                                <option value='default'>Como você conheceu a plataforma?</option>
                                <option value='Grupo de Whatsapp'>Grupo de Whatsapp</option>
                                <option value='Pesquisa no google'>Pesquisa no google</option>
                                <option value='Youtube'>Youtube</option>
                                <option value='Instagram'>Instagram</option>
                                <option value='Blog'>Blog</option>
                                <option value='Madeireiras'>Madeireiras</option>
                                <option value='Revista Infomarceneiro'>
                                    Revista Infomarceneiro
                                </option>
                                <option value='Indicação'>Indicação</option>
                                <option value='Outros'>Outros</option>
                            </select>
                        </div>
                        <div className='input-wrapper'>
                            <label>Tem marcenaria?</label>
                            <div className='d-flex p-0 border border-light'>
                                <div className='d-flex w-50 align-items-center p-0 justify-content-center'>
                                    <input
                                        onChange={(e) =>
                                            this.setState({ ...this.state, haveWoods: true })
                                        }
                                        checked={this.state.haveWoods}
                                        type='radio'
                                        id='haveWoods'
                                        name='marcenaria sim'
                                    />
                                    <label className='ml-1'>Sim</label>
                                </div>
                                <div className='d-flex w-50 align-items-center p-0 justify-content-center'>
                                    <input
                                        onChange={(e) =>
                                            this.setState({ ...this.state, haveWoods: false })
                                        }
                                        checked={!this.state.haveWoods}
                                        type='radio'
                                        id='haveWoods'
                                        name='marcenaria nao'
                                    />
                                    <label className='ml-1'>Não</label>
                                </div>
                            </div>
                        </div>
                        <div className='input-wrapper'>
                            <label>Terceiriza o corte?</label>
                            <div className='d-flex p-0 border border-light'>
                                <div className='d-flex w-50 align-items-center p-0 justify-content-center'>
                                    <input
                                        onChange={(e) =>
                                            this.setState({ ...this.state, thirdPartyCut: true })
                                        }
                                        checked={this.state.thirdPartyCut}
                                        type='radio'
                                        id='thirdPartyCutYes'
                                        name='thirdPartyCutYes'
                                    />
                                    <label className='ml-1'>Sim</label>
                                </div>
                                <div className='d-flex w-50 align-items-center p-0 justify-content-center'>
                                    <input
                                        onChange={(e) =>
                                            this.setState({ ...this.state, thirdPartyCut: false })
                                        }
                                        checked={!this.state.thirdPartyCut}
                                        type='radio'
                                        id='thirdPartyCutNo'
                                        name='thirdPartyCutNo'
                                    />
                                    <label className='ml-1'>Não</label>
                                </div>
                            </div>
                        </div>
                        <div className='input-wrapper'>
                            <label>Terceiriza Filetamento?</label>
                            <div className='d-flex p-0 border border-light'>
                                <div className='d-flex w-50 align-items-center p-0 justify-content-center'>
                                    <input
                                        onChange={(e) =>
                                            this.setState({ ...this.state, thirdPartyFillet: true })
                                        }
                                        checked={this.state.thirdPartyFillet}
                                        type='radio'
                                        id='thirdPartyFilletYes'
                                        name='thirdPartyFilletYes'
                                    />
                                    <label className='ml-1'>Sim</label>
                                </div>
                                <div className='d-flex w-50 align-items-center p-0 justify-content-center'>
                                    <input
                                        onChange={(e) =>
                                            this.setState({
                                                ...this.state,
                                                thirdPartyFillet: false
                                            })
                                        }
                                        checked={!this.state.thirdPartyFillet}
                                        type='radio'
                                        id='thirdPartyFilletNO'
                                        name='thirdPartyFilletNO'
                                    />
                                    <label className='ml-1'>Não</label>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex w-100 justify-content-center'>
                            <button className='btn btn-primary text-dark text-bold btn-rounded mt-3'>
                                Salvar
                            </button>
                        </div>
                    </form>
                </section>
            </React.Fragment>
        );
    }
}
