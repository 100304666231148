import React from "react";
import "./styles.css";
import querystring from "query-string";

import MaterialInput from "../MaterialInput";
import TextArea from "../TextArea";

import { popUp, handleMail, requests, phoneMask } from "../../utils";
import { CONSTANTS } from "../../config";
import Spinner from "../../utils/Spinner";

export default class SectionContact extends React.Component {
    constructor() {
        super();
        this.spinner = new Spinner();
        this.spinner.setTimeout(1500);
        this.state = {
            name: "",
            email: "",
            message: "",
            loggingName: "",
            loggingContactName: "",
            loggingContact: "",
            isLogging: false,
            isHideEmailAndName: false,
            subject: "",
            subjectList: []
        };
    }

    async fectchSubjects() {
        return requests({
            path: CONSTANTS.parameters,
            params: "emailSubject"
        });
    }

    componentDidMount() {
        let params = querystring.parse(window.location.search);
        let user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            if (Object.keys(params).length > 0 && params.logging) {
                this.setState({
                    ...this.state,
                    isLogging: true,
                    subject: "Indique uma madeireira",
                    email: user.email,
                    name: `${user.name} ${user.surName || ""}`,
                    isHideEmailAndName: true
                });
            } else {
                this.setState({
                    ...this.state,
                    email: user.email,
                    name: `${user.name} ${user.surName || ""}`,
                    isHideEmailAndName: true
                });
            }
        } else {
            console.error("Erro ao capturar as informações do usuário");
        }

        this.spinner.start();
        this.fectchSubjects()
            .then((res) => {
                this.spinner.finish();
                if (res && res.length > 0) {
                    this.setState({
                        ...this.state,
                        subjectList: res
                    });
                }
            })
            .catch((e) => {
                this.spinner.finish("Erro ao buscar assuntos, por favor recarregue a página.");
                console.error(e);
            });
    }

    submit = (e) => {
        e.preventDefault();
        let {
            name,
            email,
            message,
            subject,
            isLogging,
            loggingContact,
            loggingContactName,
            loggingName
        } = this.state;
        if (
            name &&
            email &&
            message &&
            subject &&
            (isLogging ? loggingContact && loggingContactName && loggingName : true)
        ) {
            if (isLogging)
                message = `
          **Nome da Marcenaria**: ${loggingName}\n
          **Nome do contato**: ${loggingContactName}\n
          **Contato:** ${loggingContact}\n

          ${message}
        `;
            handleMail(name, email, message, subject);
        } else {
            popUp.open("Por favor, preencha todos os campos para enviar a mensagem.");
        }
    };

    onChange = (e) => {
        e.preventDefault();
        let { name: field, value } = e.target;

        switch (field) {
            case "subject":
                if (value === "Indique uma madeireira")
                    this.setState({
                        ...this.state,
                        [field]: value,
                        isLogging: true
                    });
                else
                    this.setState({
                        ...this.state,
                        [field]: value,
                        isLogging: false
                    });
                break;
            case "loggingContact":
                let loggingContact = phoneMask(value);
                this.setState({ ...this.state, loggingContact });
                break;
            default:
                this.setState({ ...this.state, [field]: value });
                break;
        }
    };

    render() {
        return (
            <section
                id='contato'
                className='d-flex w-100 justify-content-center align-items-center contact-container pv-3'>
                <form
                    action='submit'
                    className='contact-form ph-1 text-center d-flex flex-column justify-content-center align-items-center'
                    onSubmit={this.submit}>
                    <h4 className='input-wrapper text-left text-light text-bold'>Contato</h4>
                    <MaterialInput
                        id='name'
                        name='name'
                        type='text'
                        placeholder='Nome'
                        value={this.state.name}
                        onChange={this.onChange}
                        hidden={this.state.isHideEmailAndName}
                    />
                    <MaterialInput
                        id='email'
                        name='email'
                        type='email'
                        placeholder='Email'
                        value={this.state.email}
                        onChange={this.onChange}
                        hidden={this.state.isHideEmailAndName}
                    />
                    <div className='select-wrapper mt-3'>
                        <select
                            onChange={this.onChange}
                            name='subject'
                            id='subject'
                            value={this.state.subject || "default"}>
                            <option value='default'>Selecione o Assunto</option>
                            {this.state.subjectList.map((subject, index) => (
                                <option key={index} value={subject}>
                                    {subject}
                                </option>
                            ))}
                        </select>
                    </div>
                    <MaterialInput
                        id='loggingName'
                        name='loggingName'
                        type='text'
                        placeholder='Nome da Madeireira'
                        value={this.state.loggingName}
                        onChange={this.onChange}
                        hidden={!this.state.isLogging}
                    />
                    <MaterialInput
                        id='loggingContact'
                        name='loggingContact'
                        type='text'
                        placeholder='Telefone da Madeireira'
                        value={this.state.loggingContact}
                        onChange={this.onChange}
                        hidden={!this.state.isLogging}
                    />
                    <MaterialInput
                        id='loggingContactName'
                        name='loggingContactName'
                        type='text'
                        placeholder='Nome do contato'
                        value={this.state.loggingContactName}
                        onChange={this.onChange}
                        hidden={!this.state.isLogging}
                    />
                    <TextArea
                        id='message'
                        name='message'
                        type='text'
                        onChange={this.onChange}
                        placeholder='Digite sua mensagem.'
                        value={this.state.message}
                    />
                    <button className='mv-4 btn btn-primary btn-rounded text-dark text-heavy'>
                        Enviar
                    </button>
                </form>
            </section>
        );
    }
}
