import styled from "styled-components";

export const PopMenuWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0.6rem;
    background-color: var(--primary);
    border-radius: 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(100% + 15px);
    box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        right: -15px;
        top: 50%;
        border: solid 7.5px var(--primary);
        transform: rotate(45deg) translateX(-11.5px);
        border-left-color: transparent;
        border-bottom-color: transparent;
    }
`;
