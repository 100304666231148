import { requests, popUp } from "../../../utils";
import Axios from "axios";
import Spinner from "../../../utils/Spinner";

export function getIdFromPath(path) {
  if (path) {
    let sliced = path.split("/");
    return sliced && sliced.length > 0
      ? sliced[sliced.length - 1].replace("/", "")
      : 0;
  } else {
    return 0;
  }
}

export function requestList() {
  return requests({
    path: "/sheet/list",
  })
    .then((res) => {
      if (res && res.error) {
        if (res.status < 405) {
          return { error: res.error };
        } else {
          return { error: "Erro na comunicação com o servidor" };
        }
      } else {
        return res;
      }
    })
    .catch((e) => {
      console.error(e);
      return { error: "Erro ao buscar lista de serviços." };
    });
}

export function removeSheet(sheetId) {
  return requests({
    path: `/sheet/${sheetId}`,
    options: {
      method: "DELETE",
    },
  })
    .then((res) => {
      if (res && res.error) {
        if (res.status < 405) {
          return { error: res.error };
        } else {
          return { error: "Erro na comunicação com o servidor" };
        }
      } else {
        return res;
      }
    })
    .catch((e) => {
      console.error(e);
      return { error: "Erro ao remover serviço." };
    });
}

export async function getSheet(id) {
  let sheet = await requestSheetDetails(id);
  return sheet;
}

export function getBrand(product) {
  if (!product || !product.name) return "";
  return `${product.brand} - ${product.name}`;
}

export function getProducts() {
  const spinner = new Spinner();
  spinner.start("Buscando produtos...");
  return Axios.get(`https://api.esobras.com.br/product/category/8`)
    .then((response) => {
      spinner.finish();
      let errorMessage = "Erro ao buscar materiais.";
      try {
        if (response.error) {
          if (response.status < 404) {
            popUp.open(response.error);
          } else {
            popUp.open(errorMessage);
          }
          return [];
        } else {
          return response.data;
        }
      } catch (error) {
        spinner.finish();
        console.error(error);
        popUp.open("Erro de comunicação com o servidor.");
        return [];
      }
    })
    .catch((e) => {
      spinner.finish();
      console.error(e);
      return [];
    });
}

export function requestSheetDetails(id) {
  return requests({
    path: `/sheet/${id}`,
  })
    .then((res) => {
      if (res && res.error) {
        if (res.status < 405) {
          return { error: res.error };
        } else {
          return { error: "Erro na comunicação com o servidor" };
        }
      } else {
        return res;
      }
    })
    .catch((e) => {
      console.error(e);
      return { error: "Erro ao buscar planilha." };
    });
}
