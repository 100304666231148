import React, { useState } from "react";
import "./styles.css";

import MaterialInput from "../MaterialInput";
import { getAddress, popUp, requests } from "../../utils";
import Spinner from "../../utils/Spinner";
import { CONSTANTS } from "../../config";
import { useHistory } from "react-router-dom";
import {isValidCPF} from "../../utils/masks"

const INITIAL_STATE = () => ({
  name: "",
  cpf: "",
  surName: "",
  email: "",
  cep: "",
  state: "",
  city: "",
  neighborhood: "",
  password: "",
  emailConfirm: "",
  howKnowsPlattform: "",
});



export default function SectionRegister() {
  let [values, setValues] = useState(INITIAL_STATE);
  const spinner = new Spinner();
  const history = useHistory();

  function onSubmit(e) {
    e.preventDefault();
    if (!values.name) {
      popUp.open("Por favor, preencher o nome.");
      return;
    }
    if (!values.surName) {
      popUp.open("Por favor, preencher o sobreNome.");
      return;
    }
    if (!values.cpf) {
      popUp.open("Por favor, preencher o CPF.");
      return;
    }
    if (!isValidCPF(values.cpf)){
      popUp.open("Por favor, digite um número de CPF valido.");
      return;
    }
    if (values.name === values.surName) {
      popUp.open(
        "Nome e Sobrenome não podem ser iguais, por favor verifique a informação."
      );
      return;
    }
    if (!values.cep) {
      popUp.open("Por favor, preencher o cep.");
      return;
    }
    if (!values.state || !values.city || !values.neighborhood) {
      popUp.open(
        "Por favor, preencher o cep novamente para busca correta dos dados."
      );
      return;
    }
    if (!values.email) {
      popUp.open("Por favor, preencher o email.");
      return;
    }
    if (!values.password) {
      popUp.open("Por favor, preencher a senha.");
      return;
    }
    if (values.email !== values.emailConfirm) {
      popUp.open("Emails não conferem!");
      return;
    }
    if (!values.howKnowsPlattform || values.howKnowsPlattform === "default") {
      popUp.open("Por favor, diga-nos como conheceu a plataforma");
      return;
    }
    const cpfUnmasked = values.cpf.replaceAll("-", "").replaceAll(".", "");


    const {
      name,
      email,
      cep,
      state,
      neighborhood,
      city,
      password,
      surName,
      howKnowsPlattform,
    } = values;

    spinner.start();
    localStorage.clear();

    requests({
      path: CONSTANTS.usersOperation,
      options: {
        method: "post",
        body: {
          name,
          "cpf": cpfUnmasked,
          surName,
          email,
          cep,
          state,
          city,
          password,
          neighborhood,
          howKnowsPlattform,
        },
      },
    })
      .then((resp) => {
        if (resp.error) {
          spinner.finish();
          if (resp.status < 404) popUp.open(resp.error);
          else
            popUp.open(
              "Erro na comunicação com o servidor, tente novamente mais tarde."
            );
        } else if (resp._id) {
          localStorage.setItem("user", JSON.stringify(resp));
          history.push("#header");
          spinner.finish();
          popUp.open(
            "Usuário cadastrado com sucesso!, verifique seu email para ativação."
          );
        } else {
          spinner.finish();
          popUp.open("Erro na criação do usuário, por favor tente novamente.");
        }
      })
      .catch((e) => {
        spinner.finish();
        popUp.open(
          "erro ao contactar o servidor, por favor tente novamente mais tarde."
        );
      });
  }

  function handleZipCode(e) {
    e.preventDefault();
    const zipCode = e.target.value.replace(/(\s?)(-?)/g, "");
    setValues({
      ...values,
      cep: zipCode,
    });
    if (zipCode.length === 8) {
      spinner.start("Buscando Cep");
      getAddress(zipCode)
        .then((response) => {
          spinner.finish();
          if (response.uf) {
            setValues({
              ...values,
              city: response.localidade || "",
              state: response.uf,
              neighborhood: response.bairro || "",
              cep: zipCode,
            });
          } else {
            popUp.open(
              "Cep inválido ou não encontrado. Por favor digite novamente ou consulte em http://www.buscacep.correios.com.br/sistemas/buscacep/"
            );
          }
        })
        .catch((e) => {
          spinner.finish();
          popUp.open(
            "Cep inválido ou não encontrado. Por favor digite novamente ou consulte em http://www.buscacep.correios.com.br/sistemas/buscacep/"
          );
        });
    }
  }

  function onChange(e) {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  }
  function validateCPF(e) {
      const cpfMask = e.target.value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
      setValues({...values, cpf: cpfMask});
    }


  return (
    <section id="register" className="container">
      <aside>
        <form
          onSubmit={onSubmit}
          className="ph-2 w-100 text-center d-flex flex-column justify-content-center align-items-center"
        >
          <h4 className="text-light pt-2">Cadastre-se gratuitamente.</h4>
          <MaterialInput
            id="name"
            onChange={onChange}
            placeholder="nome"
            name="name"
            value={values.name}
          />
          <MaterialInput
            id="surName"
            onChange={onChange}
            placeholder="sobrenome"
            name="surName"
            value={values.surName}
          />
          <MaterialInput
            id="cpf"
            onChange={validateCPF}
            placeholder="CPF"
            name="cpf"
            value={values.cpf}
          />
          <MaterialInput
            id="cep"
            maxLength={9}
            onChange={handleZipCode}
            placeholder="cep"
            name="cep"
            value={values.cep}
          />
          <MaterialInput
            id="mail"
            onChange={onChange}
            placeholder="email"
            name="email"
            value={values.email}
          />
          <MaterialInput
            id="emailConfirm"
            onChange={onChange}
            placeholder="confirme o email"
            name="emailConfirm"
            value={values.emailConfirm}
          />
          <MaterialInput
            id="pass"
            type="password"
            onChange={onChange}
            placeholder="senha"
            name="password"
            value={values.password}
          />
          <div className="select-wrapper mt-3">
            <select
              onChange={onChange}
              name="howKnowsPlattform"
              id="howKnowsPlattform"
            >
              <option value="default">Como você conheceu a plataforma?</option>
              <option value="Grupo de Whatsapp">Grupo de Whatsapp</option>
              <option value="Pesquisa no google">Pesquisa no google</option>
              <option value="Youtube">Youtube</option>
              <option value="Instagram">Instagram</option>
              <option value="Blog">Blog</option>
              <option value="Madeireiras">Madeireiras</option>
              <option value="Revista Infomarceneiro">
                Revista Infomarceneiro
              </option>
              <option value="Indicação">Indicação</option>
              <option value="Outros">Outros</option>
            </select>
          </div>
          <button
            type="submit"
            className="mv-4 btn btn-primary btn-rounded text-dark text-heavy"
          >
            Cadastrar
          </button>
        </form>
      </aside>
      <content>
        <video src=""></video>
      </content>
    </section>
  );
}
