import React, { Component,useState, useEffect,useParams } from "react";
import WebcutWhite from "../../assets/webcut-white.jpeg"
import { FcOk } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
import Pix from "../../assets/pix.png"
import { Wrapper } from "./style";
import ReactTooltip from "react-tooltip";
import {QRCodeSVG} from 'qrcode.react';
import { requests, popUp, fixAutofillInputs, dateMask, phoneMask, cepMask } from "../../utils";
import Spinner from "../../utils/Spinner";
import { CONSTANTS } from "../../config";
import { getIdFromPath } from "../Cutplan/Business/functions";
import {CopyToClipboard} from 'react-copy-to-clipboard';

function Purchase (props){
    const INITIAL_STATE = {
        credito: 10,
        valorTotal: 100,
        ValorTotal:0,
        isLogging: false,
    }; 
    const [state, setState] = useState(INITIAL_STATE);
    const [userPurchase, setUserPurchase] = useState({
        credito: 0,
        valorTotal: 0,
        status: "",
        qrCode: ""
    })
    function getPayment(id, intervall = null) {
        let spinner = new Spinner();
        spinner.start();
        requests({
            path: CONSTANTS.paymentGet,
            params: id,
            options: {
                method: "GET",
            }
        }).then((res) => {
            spinner.finish();
            if (res && res.error) {
                if (res.status < 404) {
                    popUp.open(res.error);
                } else {
                    popUp.open("Erro na comunicação com o servidor.");
                }
            } else {
                setUserPurchase({...userPurchase, credito: res.creditos, valorTotal: res.valor.original, status: res.status, qrCode: res.pixCopiaECola});
                if(res.status === "CONCLUIDA"){
                    clearInterval(intervall);
                }
            }
        }) .catch((e) => {
                spinner.finish();
                popUp.open(
                    "Erro desconhecido!\n Por favor, tente novamente mais tarde."
                );
            });

    }
    function copyAlert() {
        popUp.open("Copiado com Sucesso")
    }

    useEffect(() => {
        let id = getIdFromPath(window.location.pathname);
        getPayment(id);
        const globalInterval = setInterval(() => getPayment(id, globalInterval), 10000);
    }, []);
    return (
        <React.Fragment>
            <section className="d-flex w-100 justify-content-center align-items-center contact-container pv-3">
                <div className="contact-form ph-1 mv-5 d-flex align-items-center flex-wrap justify-content-center">
                    <div className="flex-wrap d-flex w-100 justify-content-center align-items-center pr-0 pl-0 pb-3">
                        <div className="__Card_component d-flex checkout justify-content-center align-items-center">
                            <div className="__Card_component d-flex checkout w-100 flex-wrap">     
                                <div className="d-flex justify-content-center w-100">
                                    <h5 className='input-wrapper-checkout-details text-center text-light'>Resumo da Compra</h5>
                                </div>
                                <div className="d-flex justify-content-center w-100">
                                    <Wrapper
                                        borderLines={true}
                                        headerColor="#000"
                                        bordered={"solid red"}
                                        maxHeight={'600px'}
                                        minHeight={'auto'}>
                                        <ReactTooltip />
                                        <table>
                                            <thead>
                                                <th>Item</th>
                                                <th>Valor</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Quantidade de Créditos</td>
                                                    <td>{userPurchase.credito}</td>
                                                </tr>
                                                <tr>
                                                    <td>Total</td>
                                                    <td>R$ {(userPurchase.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2})}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Wrapper>
                                </div>
                            </div>
                        </div>
                        <div className="flex-wrap d-flex w-100 justify-content-center align-items-center pr-0 pl-0 pb-3">
                            <div className="__Card_component checkout w-100 flex-wrap justify-content-center align-items-center">
                                { userPurchase.status === 'ATIVA' && (
                                    <>
                                        <h5 className='input-wrapper-checkout-details text-center text-light'>Dados Pagamento</h5>
                                        <img src={Pix} className="mb-3 mt-3" height="100px" />
                                        <div className="d-flex justify-content-center align-items-center flex-wrap w-100">
                                            <QRCodeSVG
                                              value={userPurchase.qrCode}
                                              size={240}
                                              bgColor={"#ffffff"}
                                              fgColor={"#000001"}
                                              level={"L"}
                                              includeMargin={false}
                                              imageSettings={{
                                                src: WebcutWhite,
                                                x: undefined,
                                                y: undefined,
                                                height: 40,
                                                width: 40,
                                                excavate: false,
                                              }}
                                            />
                                            <h6 className='input-wrapper-checkout-details text-center text-dark'>Valor: R$ {(userPurchase.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2})}</h6>
                                            <CopyToClipboard text={userPurchase.qrCode} onCopy={copyAlert}>
                                                <button className='mv-4 btn btn-primary btn-rounded text-dark text-heavy'>
                                                    Copiar Código
                                                </button>
                                            </CopyToClipboard>

                                        </div>

                                    </>

                                )}
                                { userPurchase.status === 'CONCLUIDA' && (
                                    <>
                                        <FcOk size="17em"/>
                                        <h5 className='input-wrapper-checkout-details text-center text-light'>Pagamento Concluido com Sucesso</h5>
                                    </>

                                )}
                                { userPurchase.status === 'cancelado' && (
                                    <>
                                        <FcCancel size="17em"/>
                                        <h5 className='input-wrapper-checkout-details text-center text-light'>Pagamento Cancelado</h5>
                                    </>

                                )}

                            
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Purchase;
