import React, { useEffect, useState } from "react";
import Spinner from "../../utils/Spinner";
import { useToasts } from "react-toast-notifications";
import SimpleTable from "../../components/SimpleTable";
import { popUp, fixAutofillInputs } from "../../utils";
import { getBrand, getProducts, requestSheetDetails, getIdFromPath } from "./Business/functions";
import { Sheet, CutPlanModel } from "./Model/SheetModel";
import {
    CutplanWrapper,
    CutplanTableWrapper,
    RoundedButton,
    CutplanHeaderWrapper,
    InputTable
} from "./styles/styles";
import ImagePreview from "../../components/ImagePreview";
import Check from "../../components/Check";
import MaterialInput from "../../components/MaterialInput";
import PopMenu from "../../components/PopMenu";
import { useHistory } from "react-router-dom";

import save from "./Business/save";

import Espessuras from "./Model/Espessuras.json";
import Columns from "./Model/Columns";
import Materiais from "./Model/Materiais.json";
import SelectSearch from "react-select-search";

import "./styles/styles.css";

export default function Cutplan(props) {
    const spinner = new Spinner();
    const history = useHistory();
    const [data, setData] = useState(new CutPlanModel());
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [showRemoveSelecteds, setShowRemoveSelecteds] = useState(false);
    const [products, setProducts] = useState({
        productNames: [],
        products: []
    });
    const { addToast } = useToasts();

    function onchange(e, id) {
        let sheet = [...data.sheet];
        let row = { ...sheet[id] };
        if (row) {
            let { name, value, type, max } = e.target;
            if (type === "number") {
                if (Number(value) > Number(max)) {
                    value = max;
                }
                let numval = Number(value) > 0 ? value.replace(/^0+/, "") : "0";
                if (!isNaN(numval)) {
                    value = numval;
                }
            }
            if (type === "checkbox") {
                value = e.target.checked;
            }
            row[name] = value;
        }
        sheet[id] = row;
        setData({
            ...data,
            sheet
        });
    }

    function onblur(e, id) {
        let sheet = [...data.sheet];
        let row = { ...sheet[id] };
        if (row) {
            let { name, value, type, min, max } = e.target;
            if (type === "number") {
                let numval = Number(value);
                if (!isNaN(numval)) {
                    if (min && max) {
                        value = numval > min ? (numval > max ? max : numval) : min;
                    } else {
                        value = numval;
                    }
                }
            }
            if (type === "checkbox") {
                value = e.target.checked;
            }

            row[name] = value;
        }
        sheet[id] = row;

        setData({
            ...data,
            sheet
        });
    }

    async function fetchProducts() {
        let products = await getProducts();
        if (products) {
            setProducts({
                productNames: products.map((prd) => getBrand(prd)),
                products
            });
        }
    }

    //primeiro método executado ao entrar na tela...
    useEffect(() => {
        fetchProducts();
        let id = getIdFromPath(window.location.pathname);

        if (id != 0) {
            setIsUpdate(true);
            spinner.start("Buscando Lista...");
            requestSheetDetails(id)
                .then((res) => {
                    setData({
                        ...res,
                        sheet: res.modelSheet || [new Sheet()],
                        sheetId: id
                    });
                })
                .catch((e) => {
                    addToast(e.error, {
                        appearance: "error"
                    });
                });
            spinner.finish();
        }
        fixAutofillInputs(`[name=clientName]+label`);
    }, []);

    useEffect(() => {
        if (isSelectedAll) {
            let sheet = data.sheet.map((sheet) => ({ ...sheet, selected: true }));
            setData({
                ...data,
                sheet
            });
        } else {
            let sheet = data.sheet.map((sheet) => ({ ...sheet, selected: false }));
            setData({
                ...data,
                sheet
            });
        }
    }, [isSelectedAll]);

    const addRow = async () => {
        let newRow = new Sheet();
        let sheet = data.sheet;
        sheet.push(newRow);
        setData({
            ...data,
            sheet
        });
    };
    const getChapas = (material) => {
        let teste = [];
        products.products.forEach((product) => {
            if (product.material.includes(material) || !material) {
                teste.push({
                    name: getBrand(product),
                    value: getBrand(product)
                    // value: getBrand(product)
                    //     .normalize("NFD")
                    //     .replace(/[\u0300-\u036f]/g, "")
                });
            }
        });
        return teste;
    };
    const duplicateRow = async (id) => {
        let newRow = data.sheet.find((_, index) => index === id);
        if (newRow) {
            newRow = new Sheet({ ...newRow });
            let sheet = data.sheet;
            sheet.push(newRow);
            setData({
                ...data,
                sheet
            });
        } else {
            addToast("Erro ao contactar o servidor", {
                apppearance: "error"
            });
        }
    };

    const removeRow = (id) => {
        popUp.open("Você realmente deseja remover a peça?", [
            {
                text: "SIM",
                handleClick: function () {
                    let sheet = data.sheet.filter((item, index) => index !== id);
                    if (sheet.length === 0) {
                        sheet.push(new Sheet());
                    }
                    setData({
                        ...data,
                        sheet
                    });
                }
            },
            {
                text: "NÃO"
            }
        ]);
    };

    function handleSearch(value, index, type) {
        let sheet = [...data.sheet];
        if (!sheet.fita && type === "chapa") {
            sheet[index].fita = value;
        } else if (type === "material") {
            sheet[index].shouldDisableProduct = false;
            sheet[index].chapa = "";
            sheet[index].fita = "";
        }
        sheet[index][type] = value;

        setData({
            ...data,
            sheet
        });
    }

    function setPreview(value, index) {
        let sheet = data.sheet.map((sheet) => ({
            ...sheet,
            showPreview: false,
            showOptions: false
        }));
        sheet[index].showPreview = value;
        setData({
            ...data,
            sheet
        });
    }

    function showOptions(value, index) {
        let sheet = data.sheet.map((sheet) => ({
            ...sheet,
            showOptions: false,
            showPreview: false
        }));
        sheet[index].showOptions = value;
        setData({
            ...data,
            sheet
        });
    }

    const getImageUrl = (item) => {
        let product = products.products.find((product) => getBrand(product) == item.chapa);
        if (product) {
            return product.imageUrl;
        }
        return "";
    };

    const removeSelectedRows = () => {
        const newSheet = data.sheet.filter((row) => !row.selected);
        if (newSheet.length === 0) {
            newSheet.push(new Sheet());
        }
        setShowRemoveSelecteds(false);
        setData({
            ...data,
            sheet: newSheet
        });
        setIsSelectedAll(false);
    };

    const shortcuts = (e) => {
        const { ctrlKey, which, target } = e;
        console.log(which);
        if (ctrlKey && (which === 68 || which === 82)) {
            e.stopPropagation();
            e.preventDefault();
            const parent = target.parentElement;
            if (parent && parent.parentElement) {
                const index =
                    parent.parentElement.tagName === "TR"
                        ? parent.parentElement.getAttribute("data-index")
                        : null;
                console.log("index: ", index);
                if (index && which === 68) duplicateRow(Number(index));
                if (index && which === 82) removeRow(Number(index));
            }
        }
        if (ctrlKey && (which === 13 || which === 65)) {
            addRow();
            return;
        }
    };

    const postData = async (data) => {
        if (data) {
            try {
                let response = await save(data);
                if (!response.valid) {
                    popUp.open(response.error);
                } else {
                    window.location = "/app";
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            popUp.open([
                {
                    text:
                        "Sem dados para enviar, por favor preencha a planilha corretamente e depois clique em salvar."
                }
            ]);
        }
    };

    return (
        <CutplanWrapper>
            <CutplanHeaderWrapper>
                <div className='d-flex w-100 flex-row pl-1 pr-1' style={{ flex: "1 0 250px" }}>
                    <MaterialInput
                        value={data.clientName}
                        className='pb-3'
                        name='clientName'
                        style={{ maxWidth: "980px !important" }}
                        onChange={function (e) {
                            setData({
                                ...data,
                                clientName: e.target.value
                            });
                        }}
                        placeholder='Nome do cliente'
                        type='text'
                    />
                </div>
                <div
                    className='flex-wrap d-flex justify-content-end'
                    style={{ whiteSpace: "nowrap", flex: "500px 1 1" }}>
                    <div
                        style={{
                            padding: ".2rem",
                            flex: "1 0 150px"
                        }}>
                        <button
                            className='w-100 btn btn-outline border-primary text-primary btn-rounded mb-1'
                            onClick={() => {
                                popUp.open("Deseja salvar as alterações antes de sair?", [
                                    {
                                        text: "SIM",
                                        handleClick: function () {
                                            postData(data);
                                        }
                                    },
                                    {
                                        text: "NÃO",
                                        handleClick: history.goBack
                                    }
                                ]);
                            }}>
                            <i className='fa fa-arrow-left mr-1'></i>
                            Voltar
                        </button>
                    </div>
                    <div
                        style={{
                            padding: ".2rem",
                            flex: "1 0 150px"
                        }}>
                        <button
                            onClick={() => postData(data)}
                            className='w-100 btn btn-outline border-primary text-primary btn-rounded mb-1'>
                            Salvar
                        </button>
                    </div>
                </div>
            </CutplanHeaderWrapper>
            <div className='d-flex w-100 justify-content-end align-items-end pb-0 mb-0'>
                <button
                    style={{ maxWidth: "350px" }}
                    disabled={!showRemoveSelecteds}
                    data-tip='remover linhas selecionadas'
                    className={`w-100 btn btn-outline border-primary text-primary btn-rounded ${
                        !showRemoveSelecteds ? "btn-disabled" : ""
                    }`}
                    onClick={() => {
                        popUp.open("Deseja mesmo remover as linhas Selecionadas?", [
                            {
                                text: "SIM",
                                handleClick: function () {
                                    removeSelectedRows();
                                }
                            },
                            {
                                text: "NÃO"
                            }
                        ]);
                    }}>
                    <i className='fa fa-trash mr-1'></i>
                    Remover Selecionados
                </button>
            </div>
            <CutplanTableWrapper onKeyDownCapture={shortcuts}>
                <SimpleTable
                    bordered={true}
                    borderLines={true}
                    headerColor='black'
                    maxHeight='800px'
                    minHeight='500px'
                    borderRows='solid thin'
                    columns={Columns.map((col) => {
                        if (col.field === "selected") {
                            return {
                                ...col,
                                children: (
                                    <>
                                        <input
                                            type='checkbox'
                                            checked={isSelectedAll}
                                            onChange={(e) => {
                                                let checked = e.target.checked;
                                                setShowRemoveSelecteds(checked);
                                                setIsSelectedAll(!isSelectedAll);
                                            }}
                                        />
                                    </>
                                )
                            };
                        }
                        return col;
                    })}
                    rows={data.sheet.map((item, index) => {
                        var newItem = { ...item };
                        newItem.htmlDataAttribute = index;
                        newItem.htmlDataAttributeName = "index";
                        newItem.shouldDisableProduct = !newItem.material;
                        newItem.order = <p className='text-dark'>{index + 1}</p>;
                        newItem.selected = (
                            <input
                                type='checkbox'
                                name='selected'
                                onChange={function (e) {
                                    const checked = e.target.checked;
                                    let numOfCheckeds = data.sheet.filter(
                                        (sheet) => sheet.selected
                                    );
                                    if (checked && !showRemoveSelecteds) {
                                        setShowRemoveSelecteds(true);
                                    }
                                    if (numOfCheckeds.length <= 1 && !checked) {
                                        setShowRemoveSelecteds(false);
                                        setIsSelectedAll(false);
                                    }
                                    onchange(e, index);
                                }}
                                checked={item.selected}
                            />
                        );
                        newItem.esp = (
                            <SelectSearch
                                search
                                name='esp'
                                placeholder='Espessura'
                                onChange={function (value) {
                                    let { val = value } = value;
                                    let row = data.sheet[index];
                                    row.esp = val;
                                    let sheet = data.sheet;
                                    sheet[index] = row;
                                    setData({ ...data, sheet });
                                }}
                                value={item.esp ? item.esp : ""}
                                options={Espessuras}
                                autoComplete='on'
                            />
                        );
                        newItem.quant = (
                            <InputTable
                                style={{ width: "50px" }}
                                min='1'
                                max='999'
                                name='quant'
                                onBlurCapture={function (e) {
                                    onblur(e, index);
                                }}
                                onChange={function (e) {
                                    onchange(e, index);
                                }}
                                value={item.quant}
                                type='number'
                            />
                        );
                        newItem.larg = (
                            <InputTable
                                style={{ width: "60px" }}
                                name='larg'
                                min='50'
                                max='1850'
                                onBlurCapture={function (e) {
                                    onblur(e, index);
                                }}
                                onChange={function (e) {
                                    onchange(e, index);
                                }}
                                value={item.larg}
                                type='number'
                            />
                        );
                        newItem.obs = (
                            <InputTable
                                name='obs'
                                onBlurCapture={function (e) {
                                    onblur(e, index);
                                }}
                                onChange={function (e) {
                                    onchange(e, index);
                                }}
                                value={item.obs}
                                type='text'
                            />
                        );
                        newItem.comp = (
                            <InputTable
                                style={{ width: "60px" }}
                                name='comp'
                                min='50'
                                max='2750'
                                onBlurCapture={function (e) {
                                    onblur(e, index);
                                }}
                                onChange={function (e) {
                                    onchange(e, index);
                                }}
                                value={item.comp}
                                type='number'
                            />
                        );
                        newItem.material = (
                            <SelectSearch
                                search
                                name='material'
                                onChange={function (value) {
                                    let { val = value } = value;
                                    handleSearch(val, index, "material");
                                }}
                                placeholder='Material'
                                value={item.material ? item.material : ""}
                                options={Materiais}
                                autoComplete='on'
                            />
                        );
                        newItem.chapa = (
                            <SelectSearch
                                disabled={newItem.shouldDisableProduct}
                                options={getChapas(item.material)}
                                search
                                onChange={function (value) {
                                    let { val = value } = value;
                                    handleSearch(val, index, "chapa");
                                }}
                                fuse={{
                                    keys: ["name"],
                                    findAllMatches: true
                                }}
                                value={item.chapa ? item.chapa : ""}
                                placeholder='Selecione a Chapa'
                            />
                        );
                        newItem.funcao = (
                            <InputTable
                                style={{ width: "90px" }}
                                name='funcao'
                                onChange={function (e) {
                                    onchange(e, index);
                                }}
                                value={item.funcao}
                            />
                        );
                        newItem.rotaciona = (
                            <Check
                                name='rotaciona'
                                handleChange={function (e) {
                                    onchange(e, index);
                                }}
                                checked={item.rotaciona}
                            />
                        );
                        newItem.fita = (
                            <SelectSearch
                                disabled={newItem.shouldDisableProduct}
                                options={getChapas(item.material)}
                                search
                                onChange={function (value) {
                                    let { val = value } = value;
                                    handleSearch(val, index, "fita");
                                }}
                                fuse={{
                                    keys: ["name"]
                                }}
                                value={item.fita ? item.fita : ""}
                                placeholder='Modelo de fita de Bordo.'
                                autoComplete='on'
                            />
                        );
                        newItem.actions = (
                            <div className='d-flex justify-content-around p-0 m-0'>
                                <RoundedButton
                                    as={"a"}
                                    onClick={function () {
                                        showOptions(!item.showOptions, index);
                                    }}>
                                    <i className='fa fa-ellipsis-v' aria-hidden='true'></i>
                                    <PopMenu hidden={!item.showOptions}>
                                        <RoundedButton
                                            data-tip='ctrl+r'
                                            onClick={function (e) {
                                                removeRow(index);
                                            }}>
                                            <i className='fa fa-trash' aria-hidden='true'></i>
                                        </RoundedButton>
                                        <RoundedButton
                                            data-tip='ctrl+enter ou ctrl+a'
                                            onClick={function (e) {
                                                addRow();
                                            }}>
                                            <i className='fa fa-plus' aria-hidden='true'></i>
                                        </RoundedButton>
                                        <RoundedButton
                                            data-tip='ctrl+d'
                                            onClick={function (e) {
                                                duplicateRow(index);
                                            }}>
                                            <i className='fa fa-clone' aria-hidden='true'></i>
                                        </RoundedButton>
                                    </PopMenu>
                                </RoundedButton>
                            </div>
                        );
                        newItem.filetacao = (
                            <div className='d-flex justify-content-around p-0 m-0'>
                                <RoundedButton
                                    as={"a"}
                                    borderRadius='0px'
                                    borderColor='transparent'
                                    borderWidth='3px'
                                    selectedBorderColor='red'
                                    borderBottom={item.bord_inf}
                                    borderTop={item.bord_sup}
                                    borderLeft={item.bord_esq}
                                    borderRight={item.bord_dir}
                                    onClick={function () {
                                        setPreview(!item.showPreview, index);
                                    }}>
                                    <i className='fa fa-image' aria-hidden='true'></i>
                                    <ImagePreview
                                        handleSelectedBorders={function (name, value) {
                                            let sheet = [...data.sheet];
                                            sheet[index][name] = value;
                                            setData({
                                                ...data,
                                                sheet
                                            });
                                        }}
                                        selectedBorders={{
                                            bord_dir: item.bord_dir,
                                            bord_esq: item.bord_esq,
                                            bord_sup: item.bord_sup,
                                            bord_inf: item.bord_inf
                                        }}
                                        hidden={!item.showPreview}
                                        isDefault={false}
                                        alt={"Preview"}
                                        img={getImageUrl(item)}
                                        caption='Preview'
                                        height={item.comp}
                                        width={item.larg}
                                        callback={(val) => setPreview(val, index)}
                                    />
                                </RoundedButton>
                            </div>
                        );
                        return newItem;
                    })}></SimpleTable>
            </CutplanTableWrapper>
        </CutplanWrapper>
    );
}
